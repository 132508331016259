
<div class="container p-2 ">
    <div class="row p-3 bg-light">
        <div class="col-9 p-3 d-flex justify-content-start">
            <h1>Claim Status</h1>
        </div>
    
        <div class="col p-2 d-flex justify-content-end">
    
            <div class="p-2 example-button-container" mat-dialog-close>
                <button style="color:white;background:#8e0101;" mat-mini-fab><mat-icon>cancel</mat-icon></button>
            </div>
    
        </div>
    </div>

    <div class="row py-4 justify-content-md-center">
        <form>
            <div class="col mx-auto">
                <mat-form-field>
                    <mat-label>Enter search keyword</mat-label>
                    <input matInput placeholder="CIR/2023/171144/1585252" [formControl]="caseId" required>
                    <mat-error *ngIf="caseId.invalid">{{getErrorMessage()}}</mat-error>

                </mat-form-field>
                <button class="btn btn-danger" (click)="getClaimEmails()" *ngIf="caseId.invalid != true">Get
                    details</button>
            </div>
        </form>
    </div>
    <div class="row py-4 justify-content-md-center">
        <div class="colmx-auto">
            <div *ngIf="cases.length <=0" class="alert alert-primary" role="alert">
                There are no records available. Please enter a case number or check back later!
            </div>
        </div>
    </div>
    <div class="row pt-2 ">
        <div class="col mx-auto">
            <p class="lead ">Case Details</p>
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


                <ng-container matColumnDef="caseId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Id </th>
                    <td mat-cell *matCellDef="let element" [attr.title]="element.name">{{element.caseId}}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
                    <td mat-cell *matCellDef="let element" [attr.title]="element.status">{{element.status}}</td>
                </ng-container>
                <ng-container matColumnDef="hospitalEmail">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Hospital Email </th>
                    <td mat-cell *matCellDef="let element" [attr.title]="element.hospitalEmail">
                        {{element.hospitalEmail}}</td>
                </ng-container>
                <ng-container matColumnDef="attachments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Attachments </th>
                    <td mat-cell *matCellDef="let element" >
                        {{parseAttachements(element.information)}}</td>
                </ng-container>
                <ng-container matColumnDef="receivedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Received Data </th>
                    <td mat-cell *matCellDef="let element" [attr.title]="element.receivedDate">{{element.receivedDate |
                        date}}</td>
                </ng-container>

                <ng-container matColumnDef="information">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Information </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button (click)="openViewHtml(parseJson(element.information),element.attachments)">
                            View
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>




        </div>
    </div>
</div>


<div class="p-3 d-none">
    <mat-dialog-actions align="end">
        <button color="accent" mat-button mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>