import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HospitalregistrationComponent } from "./hospitalregistration/hospitalregistration.component";
import { AccountactivationComponent } from "./accountactivation/accountactivation.component";
import { LoginComponent } from "./login/login.component";
import { CampaignComponent } from "./campaign/campaign.component";
import { AccountactivationfromemailComponent } from "./accountactivationfromemail/accountactivationfromemail.component";
import { AuthGuard } from "./authGuard/AuthGuard";
import { HomeComponent } from "./home/home.component";
import { ForgetPassowrdComponent } from "./forget-password/forget-password.component";
import { OrgRegistrationComponent } from "./registration/org-registration/org-registration.component";
import { AuthUserRegistrationGuard } from "./authGuard/AuthUserRegistrationGuard";
import { AuthLoginGuard } from "./authGuard/AuthLoginGuard";
import { ManageUserComponent } from "./admin/manage-user/manage-user.component";
import { PartnerComponent } from "./portal/partner/partner.component";
import { ManageCompanyComponent } from "./admin/manage-company/manage-company.component";
import { ManageRoleComponent } from "./admin/manage-role/manage-role.component";
import { ManageOrgComponent } from "./admin/manage-org/manage-org.component";
import { ProductComponent } from "./storefront/product/product.component";
import { ProfileComponent } from "./profile/profile.component";
import { ClaimsComponent } from "./portal/bg/claims/claims.component";
import { customerGuard } from "./authGuard/CustomerGuard";
import { HobnobHomeComponent } from "./portal/customer/hobnob/hobnob-home/hobnob-home.component";
import { PolicyComponent } from "./portal/customer/hobnob/policy/policy.component";
import { ClaimsNowComponent } from "./portal/customer/hobnob/claims-now/claims-now.component";
import { InboxComponent } from "./portal/bg/inbox/inbox.component";
import { ManageCouponComponent } from "./admin/manage-coupon/manage-coupon.component";
import { RegSelectionComponent } from "./reg-selection/reg-selection.component";
import { UserRegistrationComponent } from "./registration/user-registration/user-registration.component";
import { ClaimOrdersComponent } from "./portal/customer/hobnob/claim-orders/claim-orders.component";
import { HospitalLocatorComponent } from "./portal/customer/hobnob/hospital-locator/hospital-locator.component";
import { healthCloudGuard } from "./authGuard/HealthCloudGuard";
import { HealthCloudComponent } from "./portal/customer/hobnob/health-cloud/health-cloud.component";
import { ClaimOrdersAgentComponent } from "./portal/customer/hobnob/claim-orders-agent/claim-orders-agent.component";
import { ManageProductComponent } from "./admin/manage-product/manage-product.component";
import { VendorOrderComponent } from "./storefront/vendor-order/vendor-order.component";
import { VendorSupportComponent } from "./storefront/vendor-support/vendor-support.component";
import { SupportComponent } from "./admin/support/support.component";
import { CreatecaseComponent } from "./cashless/createcase/createcase.component";
import { WorkflowDashboardComponent } from "./dashboard/workflow-dashboard/workflow-dashboard.component";

import { CasedetailsComponent } from "./cashless/casedetails/casedetails.component";
import { FormComponent } from "./cashless/case/form/form.component";
import { ProcessEkypComponent } from "./process-ekyp/process-ekyp.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { OnboardingFormComponent } from "./onboarding/onboarding-form/onboarding-form.component";
import { InsuranceComponent } from "./onboarding/insurance/insurance.component";
import { ManageCasesComponent } from "./admin/manage-cases/manage-cases.component";
import { ManageHospitalsComponent } from "./admin/manage-hospitals/manage-hospitals.component";
import { ManageHospitalDetailComponent } from "./admin/manage-hospitals/manage-hospital-detail/manage-hospital-detail.component";

import { CaseDetailsComponent } from "./admin/manage-cases/case-details/case-details.component";
import { TaskDetailsComponent } from "./admin/manage-cases/task-details/task-details.component";
import { ManageHospitalProductsComponent } from "./admin/manage-hospital-products/manage-hospital-products.component";
import { ManageTPAComponent } from "./admin/manage-tpa/manage-tpa.component";
import { PolicyConsultingComponent } from "./policy-consulting/policy-consulting.component";
import { ViewFileComponent } from "./portal/bg/inbox/view-file/view-file.component";
import { ManagerInsuranceCompanyComponent } from "./admin/manage-insurance-company/manager-insurance-company.component";
import { ManagementDashboardComponent } from "./dashboard/management-dashboard/management-dashboard.component";
import { ViewMedicalReportComponent } from "./portal/hobnob/abha/view-medical-report/view-medical-report.component";
import { UpdateMedicalReportComponent } from "./portal/hobnob/abha/update-medical-report/update-medical-report.component";
import { AddMedicalReportComponent } from "./portal/hobnob/abha/add-medical-report/add-medical-report.component";
import { CreateAbhaComponent } from "./portal/hobnob/abha/create-abha/create-abha.component";
import { ManageEkypComponent } from "./admin/manage-ekyp/manage-ekyp.component";
import { AggregatorComponent } from "./registration/aggregator/aggregator.component";
import { VendorComponent } from "./registration/vendor/vendor.component";
import { ManageCategoryMasterComponent } from "./admin/manage-category-master/manage-category-master.component";
import { ManageTaskCalculationComponent } from "./admin/manage-task-calculation/manage-task-calculation.component";
import { PreauthComponent } from "./pdf/cashless/preauth/preauth.component";
import { PreAuthDetailsComponent } from "./cashless/pre-auth-details/pre-auth-details.component";
import { ManageInsuranceLogoComponent } from "./admin/manage-insurance-company/manage-insurance-logo/manage-insurance-logo.component";
import { EmailConfComponent } from "./admin/email-conf/email-conf.component";
import { ViewFilesComponent } from "./modal/view-files/view-files.component";
import { ManageWorkflowComponent } from "./admin/manage-workflow/manage-workflow.component";
import { ExternalLoginComponent } from "./admin/external-login/external-login.component";

const routes: Routes = [
  {
    path: "hospitalregistration",
    component: HospitalregistrationComponent,
  },
  {
    path: "hospitalregistration/:id",
    component: HospitalregistrationComponent,
  },
  {
    path: "accountActivation",
    component: AccountactivationfromemailComponent,
  },
  {
    path: "acountactivationComponent",
    component: AccountactivationComponent,
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthLoginGuard],
  },
  {
    path: "campaign",
    component: CampaignComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Campaigns',
    },
    canActivate: [AuthGuard],
  },
  {
    path: "home",
    component: HomeComponent,
    data: {
      breadcrumb: 'BimaGarage - Home',
    },
  },
  {
    path: "orgRegistration",
    component: OrgRegistrationComponent,
  },
  {
    path: "regSelection",
    component: RegSelectionComponent,
  },
  {
    path: 'manage-cases',
    component: ManageCasesComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Cases',
    },
  },
  {
    path: "userRegistration",
    component: UserRegistrationComponent,
  },
  {
    path: "forgetPassword",
    component: ForgetPassowrdComponent,
  },
  {
    path: "manageUser",
    component: ManageUserComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage User',
    },
  },
  {
    path: "manageCompany",
    component: ManageCompanyComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Company',
    },
  },
  {
    path: "manageRole",
    component: ManageRoleComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Role',
    },
  },
  {
    path: "manageCoupon",
    component: ManageCouponComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Coupons',
    },
  },
  {
    path: "manageProduct",
    component: ManageProductComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Products',
    },
  },
  {
    path: "manageOrg",
    component: ManageOrgComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Org',
    },
  },

  {
    path: "partner",
    component: PartnerComponent,
    data: {
      breadcrumb: 'BimaGarage - Partner',
    },
  },
  
  {
    path: "vendorRegistration",
    component: VendorComponent,
    data: {
      breadcrumb: 'BimaGarage - Vendor Registration',
    },
  },
  {
    path: "product",
    component: ProductComponent,
    data: {
      breadcrumb: 'BimaGarage - Product',
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    data: {
      breadcrumb: 'BimaGarage - Profile',
    },
  },
  {
    path: "claims",
    component: ClaimsComponent,
    data: {
      breadcrumb: 'BimaGarage - Claims Status',
    },
  },
  {
    path: "hobnob-home",
    component: HobnobHomeComponent,
    data: {
      breadcrumb: 'BimaGarage - Hobnob',
    },
    canActivate: [customerGuard],
  },
  {
    path: "hobnob-policy",
    component: PolicyComponent,
    data: {
      breadcrumb: 'BimaGarage - Hobnob Policy',
    },
    canActivate: [customerGuard],
  },
  {
    path: "claim-now",
    component: ClaimsNowComponent,
    data: {
      breadcrumb: 'BimaGarage - Hobnob Products',
    },
    canActivate: [customerGuard],
  },
  {
    path: "inbox",
    component: InboxComponent,
    data: {
      breadcrumb: 'BimaGarage - Reimbursement Process',
    },
  },
  {
    path: "claim-orders",
    component: ClaimOrdersComponent,
    data: {
      breadcrumb: 'BimaGarage - Claim Orders',
    },
  },
  {
    path: "hospital-locator",
    component: HospitalLocatorComponent,
    data: {
      breadcrumb: 'BimaGarage - Hospital Locator',
    },
  },
  {
    path: "health-cloud",
    component: HealthCloudComponent,
    data: {
      breadcrumb: 'BimaGarage - Health Cloud',
    },
    canActivate: [healthCloudGuard],
  },
  {
    path: "claim-orders-agent",
    component: ClaimOrdersAgentComponent,
    data: {
      breadcrumb: 'BimaGarage -  Agent Orders',
    },
  },
  {
    path: "vendor-order",
    component: VendorOrderComponent,
    data: {
      breadcrumb: 'BimaGarage - Vendor Order',
    },
  },
  {
    path: "vendor-support",
    component: VendorSupportComponent,
    data: {
      breadcrumb: 'BimaGarage - Vendor Support',
    },
  },
  {
    path: "support",
    component: SupportComponent,
    data: {
      breadcrumb: 'BimaGarage - Support',
    },
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "cashless/create-case",
    component: CreatecaseComponent,
    data: {
      breadcrumb: 'BimaGarage - Cashless Process',
    },
  },
  {
    path: "cashless/dashboard",
    component: WorkflowDashboardComponent,
    data: {
      breadcrumb: 'BimaGarage - Cashless Dashboard',
    },
  },
  // {
  //   path: "cashless/pre-auth",
  //   component: PreAuthComponent,
  //   data: {
  //     breadcrumb: 'BimaGarage - Cashless Pre Auth',
  //   },
  // },
  {
    path: "cashless/case/:id",
    component: CasedetailsComponent,
  },
  {
    path: "cashless/manage-case/case/:processid",
    component: CaseDetailsComponent,
  },
  {
    path: "cashless/manage-case/task/:taskId",
    component: TaskDetailsComponent,
  },
  {
    path: "cashless/case-form/:id",
    component: FormComponent,
  },
  {
    path: "process-ekyp",
    component: ProcessEkypComponent,
    data: {
      breadcrumb: 'BimaGarage - Process eKYP',
    },
  },
  {
    path: "policy-consulting",
    component: PolicyConsultingComponent,
    data: {
      breadcrumb: 'BimaGarage - Policy Consulting Process',
    },
  },
  {
    path: "hospital/onboarding",
    component: OnboardingComponent,
    data: {
      breadcrumb: 'BimaGarage - Hospital Onboarding Process',
    },
  },
  {
    path: "hospital/onboarding-form/:id",
    component: OnboardingFormComponent,
  },
  {
    path: "hospital/onboarding-add-insurance-company",
    component: InsuranceComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Hospital Insurance',
    },
  },
  {
    path: 'manage-hospital-products',
    component: ManageHospitalProductsComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Hospital Products',
    },
  },
  {
    path: 'manage-hospital-logo',
    component: ManageInsuranceLogoComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Insurance Logo',
    },
  },
  {
    path: 'manage-insurance-companies',
    component: ManagerInsuranceCompanyComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage IC',
    },
  },
  {
    path: 'manage-tpa',
    component: ManageTPAComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage TPA',
    },
  },
  {
    path: 'manage-category-master',
    component: ManageCategoryMasterComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage  Category Master',
    },
  },
  {
    path: 'manage-workflow',
    component: ManageWorkflowComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage  Workflow',
    },
  },
  {
    path: 'manage-hospitals',
    component: ManageHospitalsComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Hospitals',
    },
  },
  {
    path: 'manage-hospital/:id',
    component: ManageHospitalDetailComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage Hospitals',
    },
  },
  {
    path: 'process-ekyp/view-file',
    component: ViewFileComponent,
    data: {
      breadcrumb: 'BimaGarage - eKYP View',
    },
  },
  {
    path: 'dashboard/management-dashboard',
    component: ManagementDashboardComponent,
    data: {
      breadcrumb: 'BimaGarage - Management Dashboard',
    },
  },
 
  {
    path: 'abha/create-id',
    component: CreateAbhaComponent,
    data: {
      breadcrumb: 'BimaGarage - Create ABHA ID',
    },
  },
  {
    path: 'abha/add',
    component: AddMedicalReportComponent,
    data: {
      breadcrumb: 'BimaGarage - Add health data',
    },
  },
  {
    path: 'abha/update',
    component: UpdateMedicalReportComponent,
    data: {
      breadcrumb: 'BimaGarage - Update health data',
    },
  },
  {
    path: 'manage-ekyp',
    component: ManageEkypComponent,
    data: {
      breadcrumb: 'BimaGarage - Manage eKYP',
    },
  },
    {
      path: 'manage-task-calculation',
      component: ManageTaskCalculationComponent,
      data: {
        breadcrumb: 'BimaGarage - Manage task-calculation',
      },
  },
  {
    path: 'aggregator',
    component: AggregatorComponent,
    data: {
      breadcrumb: 'BimaGarage - Aggregator',
    },
  },
  {
    path: 'preauth',
    component: PreauthComponent,
    data: {
      breadcrumb: 'BimaGarage - Pre Auth',
    },
  },
  {
    path: 'abha/view',
    component: ViewMedicalReportComponent,
    data: {
      breadcrumb: 'BimaGarage - View health data',
    },
  },
  // {
  //   path: '',
  //   redirectTo: 'acountactivationComponent',
  //   pathMatch: 'full'
  // },
  {
    path: 'pre-auth-details/:id',
      component: PreAuthDetailsComponent,
      data: {
        breadcrumb: 'BimaGarage - Pre Auth Details',
      },
  },

  {
    path: 'email-conf',
    component: EmailConfComponent,
    data: {
      breadcrumb: 'BimaGarage - Email Conf',
    },
  },
  
  {
    path: 'view-files',
    component: ViewFilesComponent,
    data: {
      breadcrumb: 'BimaGarage - View Files' ,
    },
  },
  
  {
    path: 'ext-login',
    component: ExternalLoginComponent,
    data: {
      breadcrumb: 'BimaGarage - External Login' ,
    },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
