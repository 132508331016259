import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  getExternalMediaList(
    entityId: any,
    entityType: any,
    docType:any
  ): Observable<any> {
    return this.http
      .get<any>(
        environment.API_URL + '/externalMedia/media/'+entityType+'/'+entityId+'?docType='+docType
      )
      .pipe(catchError(this.errorHandle));
  }

  deleteExternalMedia(id:any){
    return this.http.delete(`${environment.API_URL}/hospitalmedia/`+id)
    .pipe(
      catchError(this.errorHandle)
    );
  }

  getExternalMedia(externalMediaId:any) {
    const httpOptions = {
      // responseType: 'arraybuffer' as 'json'
      // 'responseType'  : 'arraybuffer' as 'json'
      responseType: 'blob' as 'json'        //This also worked
    };
    return this.http.get<any>(`${environment.API_URL}/externalMedia/`+externalMediaId,httpOptions)
      .pipe(
        catchError(this.errorHandle)
      );
  }
  getPdfAsByteStream(externalMediaId:any) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.get(`${environment.API_URL}/hospitalmedia/`+externalMediaId, httpOptions)
    .pipe(
      catchError(this.errorHandle)
    );
  }

  // These data fields are required , entityId: any, entityType:any, category:any, sourceType:any,docType:any, startDate:any,endDate:any
  addExternalMedia(data: any) {
    return this.http
      .post<any>(
        `${environment.API_URL}/externalMedia/add`,
        data,{
          reportProgress: true,
          observe: "events"
        }
      )
      .pipe(catchError(this.errorHandle));
  }

  errorHandle(error: any) {
    let errorMessage = '';
    if (error.error.error instanceof ErrorEvent) {
      errorMessage = error.error.error;
    } else {
      errorMessage = error.error;
    }

    return throwError(() => {
      return errorMessage;
    });
  }
}
