<!-- <div mat-dialog-title>
    <h1>Update Insurance Company</h1>
</div> -->

<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Add {{data.type}} Details</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" >
      <h4>
        <i class="px-3" style="color: lightgray">* marked fields are mandatory
        </i>
      </h4>
      <mat-icon mat-dialog-close color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="configForm" (ngSubmit)="onFormSubmit()">
  <div mat-dialog-content class="content">
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Select Hospital/TPA </mat-label>
        <mat-select formControlName="hospitalOrgId" [(ngModel)]="selectedHospitalOrgId" name="hospitalOrgId">
          <mat-option *ngFor="let hospdata of hospitalOrgData" [value]="hospdata.hospitalOrgId">
            {{ hospdata.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>payorType</mat-label>
        <mat-select  formControlName="payorType" [(ngModel)]="selectedPayorType"
          name="payorType">
          <mat-option *ngFor="let category of categoryList" [value]="category.id.categoryName">
            {{ category.id.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EmailHost</mat-label>
        <input matInput type="email" placeholder="EmailHost" formControlName="receivingEmailHost" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EmailId</mat-label>
        <input matInput type="email" placeholder="EmailId" formControlName="receivingEmailId" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EmailPassword</mat-label>
        <input matInput type="text" placeholder="EmailPassword" formControlName="receivingEmailPassword" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EmailPort</mat-label>
        <input matInput type="text" placeholder="EmailPort" formControlName="receivingEmailPort" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>EmailStoreType</mat-label>
        <input matInput type="text" placeholder="EmailStoreType" formControlName="receivingEmailStoreType" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </div>
  </div>
</form>