
<div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Update Zone</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <h4>
          <i class="px-3" style="color: lightgray"
            >* marked fields are mandatory
          </i>
        </h4>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="zoneForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
      <div class="row">
              <mat-form-field appearance="outline">
                  <mat-label>Category</mat-label>
                  <input matInput type="text" placeholder="category"  formControlName="category"  >
              </mat-form-field>
        <mat-form-field appearance="outline">
                  <mat-label>InsuranceCompanyId</mat-label>
                  <input matInput type="text" placeholder="insuranceCompanyId" formControlName="insuranceCompanyId">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Zone</mat-label>
                <input matInput type="text" placeholder="zone" formControlName="zone">
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>InsuranceEmailId</mat-label>
                <input matInput type="text" placeholder="insuranceEmailId" formControlName="insuranceEmailId">
            </mat-form-field>
            </div>
    <div mat-dialog-actions class="action">
      <button mat-raised-button type="button" [mat-dialog-close]="false">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
      >
        Save
      </button>
    </div>
  