import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attachment-scroller',
  templateUrl: './attachment-scroller.component.html',
  styleUrl: './attachment-scroller.component.scss'
})
export class AttachmentScrollerComponent {
  @Input() data:any;
  @Output() fileSelected = new EventEmitter<any>();
  constructor(){

  }

  onFileSelected(item:any){
    console.log(item);
    this.fileSelected.emit(item);

  }
}
