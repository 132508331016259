
  <div class="container card">

    <!-- Old -->

    <div class="">
      <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        
        <ng-container matColumnDef="pendingTask">
          <th mat-header-cell *matHeaderCellDef>Tasks</th>
          <td mat-cell *matCellDef="let element" 
            [class]="element == selectedTask ? 'selected' : ''">
            {{ element.name }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="orderDate">
          <th mat-header-cell *matHeaderCellDef>Due Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dueDate | date : "short" || '-' }}

          </td>
        </ng-container>


        <!-- Weight Column -->
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef>Order Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element.id || '-'}}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.caseVariables?.customerUserId?.firstName + " " +
            element.caseVariables?.customerUserId?.lastName || '-'}}
          </td>
        </ng-container>

        <ng-container matColumnDef="customerMobileNumber">
          <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
          <td mat-cell *matCellDef="let element">
            {{ element.caseVariables?.customerUserId?.mobilePhone || '-'}}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="assignedTo">
          <th mat-header-cell *matHeaderCellDef>Assigned to</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.assignee != undefined">{{ user?.firstName }} {{ user?.lastName }}</span>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button" class="btn" style="color:white;background:#8e0101;"  [disabled]="element.assignee != undefined"
                (click)="acceptTask(element)">
                Accept
              </button>

              <button type="button" class="btn" style="color:white;background:#8e0101;"
                (click)="assignTask(element)" [disabled]="!isPrivileged(['CAN_ASSIGN_TASK']) ">
                Assign
              </button>
              <button type="button" class="btn" style="color:white;background:#8e0101;"
              (click)="viewTask(element)">
              View
            </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator  [length]=dataSource.data.length
      [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of Tasks!">
      </mat-paginator>
    </div>

    <hr />
    <div *ngIf="allTasks != undefined && allTasks.length <= 0" class="alert alert-primary" role="alert">
      You don't have any pending tasks at this moment. Check back later!
    </div>
  </div>