<div class="container p-3">
  <div class="row pt-3 pb-3 justify-content-md-center">
    <div class="col">
      <input type="search" (input)="handleUInput($event)" class="form-control" placeholder="Filter" aria-label="Search" />
    </div>
    
  </div>
  <mat-table class="pt-3" #table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Hospital Name </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dateCreated">
      <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.dateCreated | date : "short" }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="contactDetail">
      <mat-header-cell *matHeaderCellDef>Contact Number</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.contactDetail }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="emailId">
      <mat-header-cell *matHeaderCellDef>Contact Email</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.emailId }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="statusOfRegistration">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.statusOfRegistration }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button type="button" mat-flat-button style="color:white;background:#8e0101" (click)="openView(element)">
          View
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="demo-options">
    <div class="demo-toggles">
      <br>
      <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
        [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndex" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

  <div class="alert alert-danger mt-2 pt-1" role="alert" *ngIf="message != undefined">
    {{ message }}
  </div>

</div>