<div class="container p-3">
    <form [formGroup]="form" (ngSubmit)="login()">
        <div class=" row">
            <div class="col-6"></div>
            <label> URL</label>
            <input type="text" class="form-control my-2" formControlName="url" />
        </div>
        <div class=" row">
            <div class="col-6">
                <label> Username</label>
                <input type="text" class="form-control my-2" formControlName="username" />
            </div>
        </div>
        <div class=" row">
            <div class="col-6">
                <label> password</label>
                <input type="text" class="form-control my-2" formControlName="password" />
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 text-center">
                <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="login()">Add</button>
            </div>
        </div>
    </form>
</div>