<div class="p-3">
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>Case - {{processId}} </h1>
      </div>
      <div class="col-4 d-flex justify-content-end" mat-dialog-close>
        <mat-icon color="accent">cancel</mat-icon>
      </div>
    </div>
  </div>
  <hr />
  <mat-dialog-content class="mat-typography">
    <mat-tab-group>
      <mat-tab label="General">
        <hr />
        <form [formGroup]="generalDetailsForm" [ngClass]="{'disabled-form': generalDetailsForm.disabled}">
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Admission Date</mat-label>
              <input matInput formControlName="admissionDate">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Admission Type</mat-label>
              <input matInput formControlName="admissionType">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Discharge Date</mat-label>
              <input matInput formControlName="dischargeDate">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Type of Treatment </mat-label>
              <input matInput formControlName="typeOfTreatment">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Claim Number</mat-label>
              <input matInput formControlName="claimNumber">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Treating Doctor </mat-label>
              <input matInput formControlName="treatingDoctor">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Room Type</mat-label>
              <input matInput formControlName="roomType">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Nature of Illness </mat-label>
              <input matInput formControlName="natureOfIllness">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>File Number</mat-label>
              <input matInput formControlName="fileNumber">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Deposit Amount </mat-label>
              <input matInput formControlName="depositAmount">
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Courier Receipt</mat-label>
              <input matInput formControlName="courierReceipt">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Courier Tracking Id</mat-label>
              <input matInput formControlName="courierTrackingId">
            </mat-form-field>
          </div>
        </form>
        <!-- <form>
          <mat-form-field class="example-full-width">
            <mat-label>Admission Date</mat-label>
            <input matInput formControlName="admissionDate" />
          </mat-form-field>
        </form> -->
      </mat-tab>
      <mat-tab label="Activity">
        <div class="container p-4">
          <div class="row" *ngFor="let task of caseDetails">
            <div class="col">

              <div class="card text-center" *ngIf="task.task_name == 'New admission'">

                <div class="card-body">
                  <p class="card-text">Patient <span
                      class="h6 text-success">{{removeDoubleQuotes(task.task_data[1].value)}}
                      {{removeDoubleQuotes(task.task_data[2].value)}}</span> got admitted.</p>
                </div>

              </div>


              <div class="card text-center" *ngIf="task.task_name == 'Pre Auth'">

                <div class="card-body">
                  <p class="card-text">
                    Patient Admitted : {{getValueByName(task.task_data, 'dateOfAdmission') |date }} ,
                    {{getValueByName(task.task_data, 'timeOfAdmission') }}
                  </p>
                  <p class="card-text">
                    Estimated Cost: {{getValueByName(task.task_data , 'sumExpectedCost')}}
                  </p>
                  <p class="card-text" style="background-color: lightgreen;">
                    Claim Stage 1 is completed
                  </p>
                </div>

              </div>

              <div class="card text-center" *ngIf="task.task_name == 'Initiate Claim'">

                <div class="card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'dropdown_claimInitiationStatus') | capitalizeAndSpace }}
                  </p>
                  <p class="card-text">On : {{task.tx_date |date}}
                  </p>
                </div>

              </div>
              <div class="card text-center" *ngIf="task.task_name == 'Pending with Insurer'">

                <div class="card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'dropdown_processStatus') =='processingInitialApproval' ? 'Pre Auth Approved':
                    getValueByName(task.task_data , 'dropdown_processStatus') == 'processingEnhancedApproval'?
                    'Enhancement Approved': getValueByName(task.task_data , 'dropdown_processStatus') |
                    capitalizeAndSpace }}
                  </p>
                  <!-- <p class="card-text">On : {{task.tx_date |date}}
                  </p> -->
                </div>

              </div>
              <div class="card text-center" *ngIf="task.task_name == 'Pre Auth Approval'">

                <div class="card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'preAuthApprovalDate') | date }}
                  </p>
                  <p class="card-text">{{task.task_data[1].name }} : {{getValueByName(task.task_data ,
                    'preAuthApprovalAmount') }}
                  </p>
                  <p class="card-text">{{task.task_data[2].name }} : {{getValueByName(task.task_data , 'preAuthClaimNo')
                    }}
                  </p>
                  <p class="card-text" style="background-color: lightgreen;">
                    Claim Stage 2 is completed
                  </p>
                </div>

              </div>

              <div class="card text-center" *ngIf="task.task_name == 'In Patient'">

                <div class="row card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'inPatientStatus') | capitalizeAndSpace }}
                  </p>

                </div>

              </div>

              <div class="card text-center" *ngIf="task.task_name == 'Enhancement'">

                <div class="row card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'enhancementDateTime') | date }}
                  </p>
                  <p class="card-text">{{task.task_data[1].name }} : {{getValueByName(task.task_data ,
                    'enhancementType') | capitalizeAndSpace}}
                  </p>
                  <p class="card-text">{{task.task_data[2].name }} : {{getValueByName(task.task_data ,
                    'enhancementAmount') }}
                  </p>


                </div>

              </div>
              <div class="card text-center" *ngIf="task.task_name == 'Enhancement Approval'">

                <div class="row card-body">

                  <p class="card-text">{{task.task_data[0].name }} : {{getValueByName(task.task_data ,
                    'enhancementApprovalDate') | date }}
                  </p>
                  <p class="card-text">{{task.task_data[1].name }} : {{getValueByName(task.task_data ,
                    'enhancementApprovalAmount') }}
                  </p>


                </div>

              </div>




              <div class="card text-center" *ngIf="task.task_name == 'Final Discharge'">

                <div class="card-body">

                  <p class="card-text"> {{task.task_data[1].name}} : {{getValueByName(task.task_data ,
                    'dateOfDischarge') | date }}</p>

                  <p class="card-text"> {{task.task_data[2].name}} : {{getValueByName(task.task_data , 'finalBillDate')
                    | date }}</p>

                  <p class="card-text">{{task.task_data[3].name}} : {{getValueByName(task.task_data ,
                    'approvalInitiatedDate') | date }}</p>


                  <p class="card-text"> {{task.task_data[10].name}} : {{getValueByName(task.task_data , 'totalBill') }}
                  </p>


                </div>

              </div>
              <div class="card text-center" *ngIf="task.task_name == 'Final Approval'">

                <div class="card-body">

                  <p class="card-text"> {{task.task_data[1].name}} : {{getValueByName(task.task_data ,
                    'finalApproval') | capitalizeAndSpace}}</p>

                  <p class="card-text"> {{task.task_data[2].name}} : {{getValueByName(task.task_data , 'finalApprovalDateTime')
                    | date }}</p>

                  <p class="card-text">{{task.task_data[5].name}} : {{getValueByName(task.task_data ,
                    'finalApprovedAmount')  }}</p>


                  <p class="card-text"> {{task.task_data[6].name}} : {{getValueByName(task.task_data , 'requestedAmount') }}
                  </p>


                </div>

              </div>
              <div class="card text-center" *ngIf="task.task_name == 'File Dispatch'">

                <div class="card-body">
                  <p class="card-text">{{task.task_data[0].name }} : {{(task.task_data[0].value) | capitalizeAndSpace}}
                  </p>
  
                </div>
  
              </div>
  
              <div class="card text-center" *ngIf="task.task_name == 'Recon'">
  
                <div class="card-body">
                  <p class="card-text">{{task.task_data[21].name }} : {{(task.task_data[21].value) | capitalizeAndSpace}}
                  </p>
  
                </div>
  
              </div>
              <div class="card text-center" *ngIf="task.task_name == 'Recon Partial Settlement'">
  
                <div class="card-body">
                  <p class="card-text">{{task.task_data[0].name }} : {{(task.task_data[0].value) | capitalizeAndSpace}}
                  </p>
                  <p class="card-text">{{task.task_data[1].name }} : {{(task.task_data[1].value) | capitalizeAndSpace}}
                  </p>
                  <p class="card-text">{{task.task_data[2].name }} : {{(task.task_data[2].value) }}
                  </p>
                  <p class="card-text">{{task.task_data[3].name }} : {{(task.task_data[3].value) | date}}
                  </p>
  
                </div>
  
              </div>

            </div>

           



          </div>

        </div>
        <div class="row justify-content-center align-items-center" style="height: 70vh" *ngIf="caseDetails.length<=0">
          <div class="alert alert-primary" role="alert" style="width: fit-content;">
            Please start the process to view the status
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Extra Documents">

        <div class="row m-2 bg-light-subtle border border-light-subtle rounded-3"
          *ngIf="uploadedData.length >0 && uploadedData != undefined">

          <mat-table #table [dataSource]="dataSourceforMedia">

            <ng-conatiner matColumnDef="docType">
              <mat-header-cell *matHeaderCellDef>File Type</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.docType }} </mat-cell>
            </ng-conatiner>

            <ng-container matColumnDef="fileName">
              <mat-header-cell *matHeaderCellDef>FileName</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.filename}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.dateCreated |date}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="view">
              <mat-header-cell *matHeaderCellDef>View</mat-header-cell>
              <mat-cell *matCellDef="let element"> <a (click)="viewFile(element)"> View </a> </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>



        </div>
        <div *ngIf="dataSourceforMedia != undefined && dataSourceforMedia.data.length <= 0" class="alert alert-primary"
          role="alert">
          You don't have any files to view!
        </div>
      </mat-tab>

      <!-- <mat-tab label="Comments">

      </mat-tab> -->
    </mat-tab-group>

  </mat-dialog-content>
</div>



<div class="p-3">
  <mat-dialog-actions align="end">
    <button color="accent" mat-button mat-dialog-close>Cancel</button>

  </mat-dialog-actions>
</div>