<div class="container">
  <div mat-dialog-title>
    <div class="row p-3 py-4 bg-light">
      <div class="col-9 px-5 p-3 d-flex justify-content-start">
        <h1>Claim Details</h1>
      </div>
      <div class="col p-2 d-flex justify-content-end">
        <div class="p-2 example-button-container" mat-dialog-close>
          <button style="color: white; background: #8e0101" mat-mini-fab>
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="p-3" [innerHtml]="data.htmlData">{{ data.htmlData }}</div>
    <div class="row p-3">
      <div class="col text-end" *ngFor="let attachment of data.attachments">
        <button
          style="color: white; background: #8e0101"
          (click)="openViewFile(attachment)"
          mat-button
        >
          <mat-icon>attach_file</mat-icon>
          View
        </button>
      </div>
    </div>
    <div class="row p-3" *ngIf="data.attachments == 0">
      <div class="col-12 text-center">
        <div class="alert alert-danger" role="alert">No attachments found.</div>
      </div>
    </div>
  </mat-dialog-content>
</div>
