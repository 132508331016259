import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AgChartOptions } from 'ag-charts-community';
import { DashboardService } from 'src/app/services/dashboard.service';
import { first, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { faMagnifyingGlassChart, faClock, faFlagCheckered, faFilterCircleXmark, faGaugeSimple } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-workflow-dashboard',
  templateUrl: './workflow-dashboard.component.html',
  styleUrls: ['./workflow-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class WorkflowDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  dt:any = new Date();
  dateForm: FormGroup;
  message: any;
  error: any;
  loading: any = false;
  dashboardData:any=[];
  @Input() public dashboardParam:any;
  public options: AgChartOptions;
  public optionsCompleted: AgChartOptions;
  private unsubscribe = new Subject<void>();
  faClock = faClock;
  faGaugeSimple=faGaugeSimple;
  chartDataSubject = new Subject<any>();
  chartDataCompletedSubject = new Subject<any>();
  dataSet:any=[];

  dataSource = this.dashboardData;
  dataSourceCompleted = this.dashboardData;
  columnsToDisplay = ['taskName', 'count'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null;

  constructor(private _fb: FormBuilder, public dashboard: DashboardService) {

    
    this.dt.setMonth(this.dt.getMonth() - 3);
    this.dateForm = this._fb.group({
      
      fromDate: [this.dt, Validators.required],
     });
    this.options = {
      data: [{asset:"data",amount:3}],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
        },
      ],
    };

    this.optionsCompleted = {
      data: [{asset:"data",amount:3}],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
        },
      ],
    };

    this.chartDataSubject.subscribe((dt:any) =>{
      this.dataSet = dt;
      this.options.data = dt
//console.log("DATA SET " + JSON.stringify(this.options));
      this.options = {
        data: this.dataSet,
        series: [
          {
            type: 'pie',
            angleKey: 'amount',
            calloutLabelKey: 'asset',
            sectorLabelKey: 'amount',
            sectorLabel: {
              color: 'white',
              fontWeight: 'bold',
            },
          },
        ],
      };
    });
    this.chartDataCompletedSubject.subscribe((dt:any) =>{
      
      this.optionsCompleted = {
        data: dt,
        series: [
          {
            type: 'pie',
            angleKey: 'amount',
            calloutLabelKey: 'asset',
            sectorLabelKey: 'amount',
            sectorLabel: {
              color: 'white',
              fontWeight: 'bold',
            },
          },
        ],
      };
    });

    
  }
  ngOnDestroy(): void {
    console.log("destroyed")
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onChangeEvent(event: { value: any; }){
    //console.log(event);
    this.dt = new Date(event.value).toISOString();
//console.log(new Date(event.value));
    this.getDashboard(this.dt);
  }
  
  ngAfterViewInit(): void {
//console.log("##### AFTER VIEW INIT "+this.dashboardParam);
    this.dashboardParam = JSON.parse(this.dashboardParam)
  }
  ngOnInit(): void {
//console.log("input data:"+this.dashboardParam);
    timer(0,3000000).pipe(
      //tap((x)=> console.log(x)),
      takeUntil(this.unsubscribe),
      switchMap(async () => this.getDashboard(this.dt.toISOString()))
    ).subscribe();

    //this.getCashlessDashboard();
    
  }

 
  avgDurationCompletedProcesses:number = 0;
  getDashboard(dt:any){
    
    this.dashboard
      .getDashboard(this.dashboardParam.processType,null, dt,"")
      .pipe(first())
      .subscribe({
        next: (data: any) => {
//console.log(data);
          this.dashboardData = data;
          this.dataSource = data.tasksInProgress;
          this.dataSourceCompleted = data.tasksCompleted
          var info:any[]=[];
          var infoCompleted:any[]=[];
          
          for (var x = 0; x < data.tasksInProgress.length; x++) {
            var d = data.tasksInProgress[x];
            var name = d.taskName;
            var count = d.count;
            info.push({ asset: name, amount: count });
          }

          for (var x = 0; x < data.tasksCompleted.length; x++) {
            var d = data.tasksCompleted[x];
            var name = d.taskName;
            var count = d.count;
            infoCompleted.push({ asset: name, amount: count });
          }
          var avgCount = 0 ;
          for (var x = 0; x < data.completedInstances.length; x++) {
            var d = data.completedInstances[x];
            avgCount += d.duration;
          }
          if(avgCount != 0){
            this.avgDurationCompletedProcesses = (avgCount/(data.completedInstances.length*60*60*100));
          }
          

          this.loading = false;
          this.chartDataSubject.next(info);
          this.chartDataCompletedSubject.next(infoCompleted);

        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getStatus(startDate:any,dueDate:any){
    if(dueDate  < new Date().getTime()){
      return "Overdue";
    }else{
      return "Good to go";
    }
  }
}
