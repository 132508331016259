import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';


@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent {

  roleForm: FormGroup;
  orgId:any;
  user:any;
  selectedType:number[]= [] ;
  roles:any;
  priveleges:any;
  filteredArr :any;
  selectedIds :number[]= [] ;

  
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private toastrService:ToastrService,
    private _dialogRef: MatDialogRef<UpdateRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   // @Inject(MAT_DIALOG_DATA) public orgId: any,

    //private _coreService: CoreService
  ) {
    
    this.roleForm = this._fb.group({
      id:'',
      roleName:['',Validators.required],
      tags:['',Validators.required],
      roleDescription:['',Validators.required],
      privileges:'',

    });
  }

  ngOnInit(): void {

    this.roleForm.patchValue(this.data);
    console.log(this.data)
    this.cellClicked();
    
   }

   cellClicked() {
    this.roles = this.data;
    this.adminService.getPrivileges()
      .pipe(first())
      .subscribe({
        next: (privileges: any) => {
          this.priveleges = privileges;
          this.filteredArr = [];
          this.roles.rolePrivileges?.forEach((role: any) => {
            const matchingPrivileges = this.priveleges?.filter((privilege: any) =>
              role.id.privilegeId === privilege.id

              )
            this.filteredArr = this.filteredArr.concat(matchingPrivileges);
            this.filteredArr.forEach((priv:any)=>{
              this.selectedType.push(priv.id);
            })        
          });
          console.log("Filtered privileges:", this.filteredArr);
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
  onFormSubmit() {

   var data=  {
      "roleId": this.roleForm.value.id,
      "roleDescription": this.roleForm.value.roleDescription,
      "tags": this.roleForm.value.tags,
      "privileges": 
        this.selectedType,
      
      "roleName": this.roleForm.value.roleName
    }
    if (this.roleForm.valid) {
      if (this.data) {
        this.adminService
          .updateRole( data)
          .subscribe({
            next: (val: any) => {
              this.toastrService.success("Role has been updated successfully");


              this._dialogRef.close(true);
            },
            error: (err: any) => {
              console.error(err);
              // this.toastrService.error("Error");
              this.toastrService.error(JSON.stringify(err.error.error));


            },
          });
      } 
      }
    }
  
}