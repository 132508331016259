<!-- <div>
    <h1 mat-dialog-title>User Privilages</h1>
    <div mat-dialog-content *ngFor="let data of filteredArr">
         <p>Privilege : {{data.privilege}}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
</div> -->
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>User Privilages</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="container">
    <ul *ngIf='roles'>
      <li *ngFor="let data of filteredArr">
        <span>&#10003; {{ data.privilege }}</span>
      </li>
      <!-- <li *ngFor="let priv of roles[0].privileges">
        <span>&#10003; {{priv.privilegeName}}</span>
      </li> -->
      <!-- <li *ngFor="let priv of roles">
        <span>&#10003; {{priv.privileges.privilegeName| json}}</span>
      </li> -->
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
