import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

import { ViewFileExternalMediaComponent } from "../../../inbox/view-file-external-media/view-file-external-media.component";

@Component({
  selector: "app-claim-details",
  templateUrl: "./claim-details.component.html",
  styleUrl: "./claim-details.component.scss",
})
export class ClaimDetailsComponent implements OnInit {
  constructor(
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(JSON.stringify(data));
  }
  ngOnInit(): void {}

  openViewFile(metaData: any) {
    const dialogRef = this._dialog.open(ViewFileExternalMediaComponent, {
      disableClose:true,
      data: { fileMetaData: metaData },
      width: "90%",
      // height: "70%",
    });
    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
        }
      },
    });
  }
}
