import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { ViewUpdateKypComponent } from 'src/app/modal/view-update-kyp/view-update-kyp.component';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/authService';
import { CustomerService } from 'src/app/services/customer.service';
import { AddKypUserComponent } from './add-kyp-user/add-kyp-user.component';

@Component({
  selector: 'app-manage-ekyp',
  templateUrl: './manage-ekyp.component.html',
  styleUrls: ['./manage-ekyp.component.scss']
})
export class ManageEkypComponent implements OnInit{
 
  user:any;
  selectedPolicy:any;
  policyList:any[]=[];
  dataSet :any =[];
  dataSource = new MatTableDataSource<any>(this.dataSet);
  displayedColumns = ['customerName' ,'policyNumber','insuranceCompany','insuranceProduct','sumInsured', 'action'];
  @ViewChild(MatPaginator ,{static: true}) paginator!: MatPaginator;
  constructor(
    private customerService: CustomerService,
    private authService:AuthService,
    private _dialog:MatDialog
  ){}
  ngOnInit(): void {
    this.authService.user.subscribe(x => this.user = x);
    this.dataSource.paginator = this.paginator;
   // this.getPolicies();
  }
  
  getDetailsByPolicyNumber(){
    this.customerService.getProcessEkypDetailsByPolicyId(this.selectedPolicy)
    .pipe(first())
    .subscribe((data:any[]) =>{
      console.log(data);
      this.dataSet = data;
      this.dataSource.data = this.dataSet;

    })
  }

  getDetailsByCustomerId(){

  }

  handleUInput(event:any){
    const query = event.target.value;
   this.selectedPolicy = query;
  }
  


  getPolicies(){
    this.customerService.getPolicy(this.user.id)
    .pipe(first())
    .subscribe(data =>{
      console.log(data);
      this.policyList = data;
    }
    )
  }

  addKYP(){
    console.log("hello")
    const dialogRef = this._dialog.open(AddKypUserComponent,{
      disableClose:true,
      height:'80%',
      width:'80%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val)=> {
       
      },
    });
  }

  parseId(id: string) {
    var policyName = id.split("-");
    var getName = policyName[policyName.length-1].split("_");
  // console.log(getName[getName.length-1])
    return getName[getName.length-1];
  }


  clear(){
    this.selectedPolicy = null;
  }

  viewCaseDetails(policy:any){
    const dialogRef = this._dialog.open(ViewUpdateKypComponent,{
      disableClose:true,
      data:{
        policy: policy,
        // policyId:policy.policyNumber,
         id: policy.id,
       // member_name:this.memberName,
        //taskId:policy.id
        // data:policy,
        // order_id:order_id,
        // member_name:this.memberName,
        // taskId:taskId
      },
      height:'80%',
      width:'80%'
    });
    dialogRef.afterClosed().subscribe({
      next: (val)=> {
       
      },
    });
  }

}
