<!-- CONTENT -->
 <div class="row">
  <div class="col">
    <div [formGroup]="dateForm">
      <mat-form-field>
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fromDate" (dateChange)="onChangeEvent($event)">
          <mat-hint>MM/DD/YYYY</mat-hint>
          
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
  </div>
 </div>
  <div class="row bg-white" *ngIf="dashboardData != undefined">
    <div class="col-4 p-3">
      <div class="card bg-light">
        <div class="card-body">
          <div class="row">
            <div class="col-4"><span class="badge fs-2 " style="background:rgb(233, 233, 233)"><fa-icon class="fa-xl"
                  [icon]="faClock"></fa-icon></span></div>
            <div class="col text-end">
              <h3>Completed (Count)</h3>
              <div class="fs-1" style="color:rgb(118, 220, 159)">{{dashboardData.processCompleted}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 p-3">
      <div class="card bg-light">
        <div class="card-body ">
          <div class="row">
            <div class="col-4"><span class="badge fs-2 " style="background:rgb(202, 202, 202)"><fa-icon class="fa-xl"
                  [icon]="faClock"></fa-icon></span></div>
            <div class="col text-end">
              <h3>In-Progress (Count)</h3>
              <div class="fs-1" style="color:rgb(255, 206, 44)">{{dashboardData.processInProgress}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 p-3 ">
      <div class="card bg-light">
        <div class="card-body">
          <div class="row">
            <div class="col-4"><span class="badge fs-2 " style="background:rgb(157, 157, 157)"><fa-icon class="fa-xl"
                  [icon]="faGaugeSimple"></fa-icon></span></div>
            <div class="col text-end">
              <h3>Avg. process duration (Mins)</h3>
              <div class="fs-1" style="color:cornflowerblue">{{avgDurationCompletedProcesses | number:'3.0-0'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="row pt-4">
    <div class="col">

      <div class="card ">
        <div class="card-header" style="background:white;">
          <h2><strong>Case in Progress - Tasks in progress</strong></h2>
        </div>
        <ag-charts [options]="options"></ag-charts>
      </div>
    </div>
    <div class="col">

      <div class="card ">
        <div class="card-header" style="background:white;">
          <h2><strong>Case in Progress - Completed Tasks so far</strong></h2>
        </div>
        <ag-charts [options]="optionsCompleted"></ag-charts>
      </div>
    </div>

  </div>
  <div class="row pt-3">
    <div class="col">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows >
        @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
        }
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              @if (expandedElement === element) {
              <mat-icon>keyboard_arrow_up</mat-icon>
              } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
              }
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <!--<div class="example-element-diagram">
                <div class="example-element-position"> {{element.taskName}} </div>
                <div class="example-element-symbol"> {{element.count}} </div>
              </div>-->
              <div class="example-element-description">
                <div class="row ">
                  
                  <div class="col-5 ">
                    <b>Assignee</b>
                  </div>
                  <div class="col ">
                    <b>Start Date</b>
                  </div>
                  <div class="col ">
                    <b>Due Date</b>
                  </div>
                  <div class="col ">
                    <b>Status</b>
                  </div>
                </div>
                <div class="row  " *ngFor="let detail of element.details">
                  
                  <div class="col-4 ">
                    {{detail.assignee}}
                  </div>
                  <div class="col ">
                    {{detail.startDateTime | date}}
                  </div>
                  <div class="col ">
                    {{detail.dueDate | date}}
                  </div>
                  <div class="col " [class]="getStatus(detail.startDateTime,detail.dueDate) == 'Overdue'?'text-danger':'text-success'">
                    {{getStatus(detail.startDateTime,detail.dueDate)}}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <div class="col">
      <table mat-table [dataSource]="dataSourceCompleted" multiTemplateDataRows >
        @for (column of columnsToDisplay; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
        }
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              @if (expandedElement === element) {
              <mat-icon>keyboard_arrow_up</mat-icon>
              } @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
              }
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <!--<div class="example-element-diagram">
                <div class="example-element-position"> {{element.taskName}} </div>
                <div class="example-element-symbol"> {{element.count}} </div>
              </div>-->
              <div class="example-element-description">
                <div class="row ">
                  
                  <div class="col-5 ">
                    <b>Assignee</b>
                  </div>
                  <div class="col ">
                    <b>Start Date</b>
                  </div>
                  <div class="col ">
                    <b>Due Date</b>
                  </div>
                  <div class="col ">
                    <b>Status</b>
                  </div>
                </div>
                <div class="row  " *ngFor="let detail of element.details">
                  
                  <div class="col-4 ">
                    {{detail.assignee}}
                  </div>
                  <div class="col ">
                    {{detail.startDateTime | date}}
                  </div>
                  <div class="col ">
                    {{detail.dueDate | date}}
                  </div>
                  <div class="col " [class]="getStatus(detail.startDateTime,detail.dueDate) == 'Overdue'?'text-danger':'text-success'">
                    {{getStatus(detail.startDateTime,detail.dueDate)}}
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
