<div class="container p-3" *ngIf = "preAuthData != undefined">
    <div class="row p-3">
        <div class="col text-end">
            <button class="btn" style="color:#8e0101;" (click)="downloadPDF()">Download PreAuth <mat-icon
                    aria-hidden="false" aria-label="Download PreAuth" fontIcon="download"></mat-icon></button>
                    <button mat-dialog-close style="color:white;background:#8e0101;" mat-mini-fab><mat-icon>cancel</mat-icon></button>
        </div>
    </div>
    <div class="p-3 bg-white" id="preauth-content" styl="page-break-after: always; ">
        
        <div class="row pt-3" *ngIf="insurance != undefined && insurance.logo != null;">
            <div class="col-4">
                <img
                width="30%"
                class="img-thumbnail"
                [src]="apiUrl + '/externalMedia/' + insurance.logo"
              />
            </div>
            <div class="col" *ngIf="insurance != undefined && insurance.tpaName == null;">
                <h3>{{insurance.nameReg}}</h3>
                <p>{{insurance.claimSupportAddr}} - {{insurance.claimSupportNumber}} - {{insurance.claimSupportEmail}}</p>
                
            </div>
            <div class="col" *ngIf="insurance != undefined && insurance.tpaName != null;">
                <h3>{{insurance.tpaName}}</h3>
                <p>{{insurance.address}} - {{insurance.city}} - {{insurance.state}} {{insurance.pincode}}</p>
                <p>{{insurance.emailId}}</p>
            </div>
        </div>
        <div class="row pt-3" *ngIf="insurance != undefined && insurance.logo == null;">
            <h4 *ngIf="insurance != undefined && insurance.nameReg != null;">
                <strong>{{insurance.nameReg}}</strong>
            </h4>
            <h4 *ngIf="insurance != undefined && insurance.tpaName != null;">
                <strong>{{insurance.tpaName}}</strong>
            </h4>
        </div>
        <div class="row p-2">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4>TO BE FILLED BY INSURED/PATIENT</h4>
            </div>
            <div class="col-3 hr-line">

            </div>
        </div>

        <div class="row p-2">
            <div class="col-3">

            </div>
            <div class="col-6">

            </div>
            <div class="col-3">
                TO BE FILLED IN BLOCK LETTERS

            </div>
        </div>
        <div class="row p-2">
            <div class="col-2">
                Name of the Patient
            </div>

            <div class="col text-start" >
                <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.firstname)">{{ g !== " " ? g :
                    "&nbsp;" }}</span>
                <span class="text-center bx">&nbsp;</span>
                <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.lastname)">{{n}}</span>
                <span class="text-center bx" *ngFor="let g of counter(remaining)">&nbsp;</span>
            </div>

        </div>
        <div class="row p-2">
            <div class="col-2">
                Gender
            </div>
            <div class="col text-start">
                <div>
                    MALE <span class="text-center bx">{{convertToParagraph(preAuthData.gender) == 'MALE'? '&#10003;' :
                        '&nbsp;'}}</span> &nbsp;&nbsp; FEMALE <span class="text-center bx">{{convertToParagraph(preAuthData.gender)
                        == 'FEMALE'? '&#10003;' : '&nbsp;'}}</span>
                    &nbsp;&nbsp;
                    Contact no. 
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.contactNumber)">{{n}}</span>
                    &nbsp;&nbsp;Alt. Contact no.
                    <span *ngIf="preAuthData.contactNumber">
                        <span class="text-center bx"
                            *ngFor="let n of convertToArray(preAuthData.contactNumber)">{{n}}</span>
                    </span>
                    <span *ngIf="!preAuthData.contactNumber">
                        <span class="text-center bx" *ngFor="let n of counter(10)">&nbsp;</span>
                    </span>
                </div>
            </div>

        </div>
        <div class="row p-2">
            <div class="col-2">
                Age. Years
              
            </div>
            <div class="col text-start">
              
                
                <span class="text-center bx" *ngFor="let n of ageFormat(age)">{{n}}</span> Years
                &nbsp; 
                <span class="text-center bx" *ngFor="let n of ageFormat(months)">{{n}}</span> Months
                Date of Birth 
                <span class="text-center bx" *ngFor="let n of dob">{{n}}</span>
                &nbsp;&nbsp;
                Insurer's ID card no. 
                <ng-container *ngIf="preAuthData.idNumber; else noidNumber">
                    <span class="text-center bx"
                    *ngFor="let g of convertToArray(preAuthData.idNumber)">{{g}}</span>
                </ng-container>
                <ng-template #noidNumber>
                    <span class="text-center bx" *ngFor="let g of counter(10)">&nbsp;</span>
                </ng-template>

                
            </div>

        </div>

        <div class="row p-2">
            <div class="col">
                Policy number/Name of corporate:

                <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.policyNumber)">{{g}}</span>
                Employee ID:
                <ng-container *ngIf="preAuthData.employeeId; else showBlankSpaces">
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.employeeId)">{{n}}</span>
                </ng-container>
                <ng-template #showBlankSpaces>
                    <span class="text-center bx" *ngFor="let g of counter(10)">&nbsp;</span>
                </ng-template>

                <!-- <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.employeeId)">{{g}}</span> -->
            </div>

        </div>
        <div class="row p-2">
            <div class="col">
                Currently do you have any other medical claim/health Insurance::
                Yes<span class="text-center bx">{{convertToParagraph(preAuthData.mediclaimExists) ==
                    'MEDICLAIMEXISTSYES'?' &#10003;': '&nbsp;'}}</span> No <span
                    class="text-center bx">{{convertToParagraph(preAuthData.mediclaimExists) == 'MEDICLAIMEXISTSNO'?'
                    &#10003;': '&nbsp;'}}</span>
                &nbsp;&nbsp;

                Insurer name:
                <ng-container *ngIf="preAuthData.insurer; else showBlankSpaces">
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.insurer)">{{n}}</span>
                </ng-container>
                <ng-template #showBlankSpaces>
                    <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span>
                </ng-template>


            </div>

        </div>
        <div class="row p-2">
            <div class="col">
                Give details:
            </div>
            <div class="col">

                <textarea cols="120" rows="4"></textarea>
            </div>


        </div>

        <div class="row p-2">
            <div class="col">
                Do you have a family physician, if yes:
                &nbsp;&nbsp;Name:
                <span *ngIf="preAuthData.familyPhysicianName; else familyPhysicianName">
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.familyPhysicianName)">{{n}}</span>
                </span>
                <ng-template #familyPhysicianName>
                    <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span>
                </ng-template>

                <!-- <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span> -->
                &nbsp;&nbsp;Contact no:
                <span *ngIf="preAuthData.familyPhysicianContact; else familyPhysicianContact">
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.familyPhysicianContact)">{{n}}</span>
                </span>
                <ng-template #familyPhysicianContact>
                    <span class="text-center bx" *ngFor="let g of counter(10)">&nbsp;</span>
                </ng-template>

            </div>
        </div>
        <div class="row p-2">
            <div class="col">
                Occupation of insured patient:&nbsp;&nbsp;
                <!-- <span class="text-center bx" *ngFor="let n of counter(getBlankSpaces(preAuthData.occupationOfInsured))">&nbsp;</span> -->

                <span *ngIf="preAuthData.occupationOfInsured; else occupationOfInsured">
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.occupationOfInsured)">{{n}}</span>
                </span>
                <ng-template #occupationOfInsured>
                    <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span>
                </ng-template>

            </div>

        </div>

        <div class="row p-2">
            <div class="col">
                Address of insured patient:
            </div>
            <div class="col">
                <span *ngIf="preAuthData.address; else address">

                    &nbsp;&nbsp; <textarea cols="120" rows="4">
                        {{ 
                            (preAuthData.address ? convertToParagraph(preAuthData.address) : '') + 
                            (preAuthData.city ? ', ' + convertToParagraph(preAuthData.city) : '') + 
                            (preAuthData.state ? ', ' + convertToParagraph(preAuthData.state) : '') + 
                            (preAuthData.country ? ', ' + convertToParagraph(preAuthData.country) : '')+
                            (preAuthData.zipcode ? ', ' + convertToParagraph(preAuthData.zipcode) : '')
                          }}
                     </textarea>
                </span>
                <ng-template #address>
                    <textarea cols="120" rows="4">

                     </textarea>
                </ng-template>

            </div>
        </div>
        <div class="row p-4">
            <div class="col-3 hr-line">
                &nbsp;
            </div>
            <div class="col-6 text-center">
                <h4>TO BE FILLED BY THE TREATING DOCTOR/HOSPITAL</h4>
            </div>
            <div class="col-3 hr-line">

            </div>
        </div>
        <div class="row p-2">
            <div class="col-2">
                Name of the treating Doctor:
            </div>
            <div class="col text-start">
                &nbsp;
                <span *ngIf="preAuthData.treatingDoctorName; else treatingDoctorName">
                    <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.treatingDoctorName)">{{ g
                        !== " " ? g : "&nbsp;" }}</span>
                    &nbsp; &nbsp; &nbsp;
                </span>
                <ng-template #treatingDoctorName>
                    <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span>
                </ng-template>

                Contact No: &nbsp;
                <span *ngIf="preAuthData.treatingDoctorContactNumber; else treatingDoctorContactNumber">
                    <span class="text-center bx"
                        *ngFor="let g of convertToArray(preAuthData.treatingDoctorContactNumber)">{{ g !== null ? g :
                        "&nbsp;" }}</span>
                </span>
                <ng-template #treatingDoctorContactNumber>
                    <span class="text-center bx" *ngFor="let g of counter(10)">&nbsp;</span>
                </ng-template>

            </div>

        </div>


        <div class="row p-2">
            <div class="col-6 text-start">
                Name of Illness/disease with presenting complaints:
            </div>
            <div class="col-6 text-start">
                Relevant clinical findings:
            </div>
        </div>
        <div class="row p-2">
            <div class="col-6 text-start">
                <span *ngIf="preAuthData.natureOfComplaint; else natureOfComplaint">

                    &nbsp;&nbsp; <textarea cols="50" rows="3">
                        {{convertToParagraph(preAuthData.natureOfComplaint)}}
                     </textarea>
                </span>
                <ng-template #natureOfComplaint>
                    <textarea cols="50" rows="3">

                     </textarea>
                </ng-template>

            </div>
            <div class="col-6 text-start">
                <span *ngIf="preAuthData.clinicalFindings; else clinicalFindings">

                    &nbsp;&nbsp; <textarea cols="50" rows="3">
                        {{convertToParagraph(preAuthData.clinicalFindings)}}
                     </textarea>
                </span>
                <ng-template #clinicalFindings>
                    <textarea cols="50" rows="3">

                     </textarea>
                </ng-template>
            </div>
        </div>

        <div class="row p-2">
            <div class="col text-start">
                Duration of the present ailment:
                <span *ngIf="preAuthData.durationOfAilments; else durationOfAilments">

                    &nbsp;&nbsp;<input class="text" size="5"
                        [value]="convertToParagraph(preAuthData.durationOfAilments)">&nbsp;&nbsp; Days
                </span>
                <ng-template #durationOfAilments>
                    <input class="text" size="5">&nbsp;&nbsp; Days
                </ng-template>


            </div>
            <div class="col">
                &nbsp; &nbsp; &nbsp;Date of first consultation:
                &nbsp;
                <span *ngIf="preAuthData.firstConsultationDate; else firstConsultationDate">

                    &nbsp;&nbsp;
                    <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                    <!-- <span class="text-center bx" *ngFor="let n of firstConsultationDate ">{{n}}</span> -->

                </span>
                <ng-template #firstConsultationDate>
                    <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>

            </div>
        </div>

        <div class="row p-2">
            <div class="col">
                Provisional diagnosis:
            </div>
            <div class="col">
                ICD 10 code:
            </div>
        </div>
        <div class="row p-2">
            <div class="col">
                <span *ngIf="preAuthData.provisionalDiagnosis; else provisionalDiagnosis">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="70">
                        {{convertToParagraph(preAuthData.provisionalDiagnosis)}}
                    </textarea>
                </span>
                <ng-template #provisionalDiagnosis>
                    <textarea rows="3" cols="70">

                    </textarea>
                </ng-template>

            </div>
            <div class="col">
                &nbsp;
                <span *ngIf="preAuthData.icdCode; else icdCode">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="70">
                        {{convertToArray(preAuthData.icdCode)}}
                    </textarea>
                </span>
                <ng-template #icdCode>
                    <span class="text-center bx" *ngFor="let n of counter(10)">&nbsp;</span>
                </ng-template>

            </div>
        </div>

        <div class="row p-2">
            <div class="col-4">
                Past history of present ailment if any:
            </div>
            <div class="col text-start">
                <span
                    *ngIf="preAuthData.historyOfPresentAilment == 'HISTORYOFPRESENTAILMENTYES'; else historyOfPresentAilment">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="80">
                        {{convertToParagraph(preAuthData.historyOfPresentAilment)}}
                    </textarea>
                </span>
                <ng-template #historyOfPresentAilment>
                    <textarea cols="80" rows="3"></textarea>
                </ng-template>

            </div>
        </div>

        <div class="row p-2">
            <div class="col">
        
                Proposed line of treatment: &nbsp;&nbsp; <span
                    class="text-center bx">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes('MEDICALMANAGEMENT')
                    ? '&#10003;':'&nbsp;'}}</span> Medical management
                &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                    ('SURGICALMANAGEMENT')? '&#10003;':'&nbsp;'}}</span> Surgical management
                &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                    ('INTENSIVECARE')? '&#10003;':'&nbsp;'}}</span> Intensive care
                &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                    ('INVESTIGATION')? '&#10003;':'&nbsp;'}}</span> Investigation
                &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-proposedLineOfTreatment']).includes
                    ('NONALLOPATHICTREATMENT')? '&#10003;':'&nbsp;'}}</span> Non-Allopathic treatment

            </div>

        </div>

        <div class="row p-2">
            <div class="col-6">
                If investigation and/or medical management, provide details:
            </div>
            <div class="col-6">Route of drug administration:</div>
        </div>
        <div class="row p-2">
            <div class="col-6">
                <textarea cols="50" rows="3"></textarea>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col">
                        &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-drugAdministrationRoute']).includes
                    ('IV')? '&#10003;':'&nbsp;'}}</span> IV
                        &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-drugAdministrationRoute']).includes
                    ('ORAL')? '&#10003;':'&nbsp;'}}</span> Oral
                        &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData['select-multi-drugAdministrationRoute']).includes
                    ('OTHER')? '&#10003;':'&nbsp;'}}</span> Other
                    </div>
                    <div class="col">
                        <textarea cols="30" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-2">
            <div class="col">
                If Surgical, name of surgery:
            </div>
            <div class="col">
                ICD 10 PCS code:
            </div>
        </div>
        <div class="row p-2">
            <div class="col ">
                <span *ngIf="preAuthData.surgeryName ; else surgeryName">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="50">
                        {{convertToParagraph(preAuthData.surgeryName)}}
                    </textarea>
                </span>
                <ng-template #surgeryName>
                    <textarea cols="50" rows="3"></textarea>
                </ng-template>

            </div>
            <div class="col">

                &nbsp;
                <span *ngIf="preAuthData.icd10PCSCode ; else icd10PCSCode">

                    &nbsp;&nbsp;
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.icd10PCSCode)">&nbsp;</span>
                </span>
                <ng-template #icd10PCSCode>
                    <span class="text-center bx" *ngFor="let n of counter(10)">&nbsp;</span>
                </ng-template>

            </div>
        </div>

        <div class="row p-2">
            <div class="col m-2 text-start">
                If other treatments provide details:
            </div>
            <div class="col m-2 text-start">
                How did injury occur:
            </div>
        </div>
        <div class="row p-2">
            <div class="col-6 text-start ">
                <span *ngIf="preAuthData.otherTreatment ; else otherTreatment">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="50">
                        {{convertToParagraph(preAuthData.otherTreatment)}}
                    </textarea>
                </span>
                <ng-template #otherTreatment>
                    <textarea cols="50" rows="3"></textarea>
                </ng-template>

            </div>
            <div class="col-6 text-start ">
                <span *ngIf="preAuthData.injuryReason ; else injuryReason">

                    &nbsp;&nbsp;
                    <textarea rows="3" cols="50">
                        {{  convertToParagraph(preAuthData.injuryReason)}}
                    </textarea>
                </span>
                <ng-template #injuryReason>
                    <textarea cols="50" rows="3"></textarea>
                </ng-template>

            </div>
        </div>
        <div class="row p-2">
            <div class="col text-start">
                In case of accident: &nbsp;&nbsp; Is it RTA:&nbsp;&nbsp; Yes<span
                    class="text-center bx">{{convertToParagraph(preAuthData.rta) ==
                    'RTAYES' ?'&#10003;':'&nbsp;'}}</span>
                No <span class="text-center bx">{{convertToParagraph(preAuthData.rta) == 'RTANO'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;&nbsp;
                Date of Injury:&nbsp;&nbsp;

                <span class="text-center bx" *ngFor="let n of counter(10)">&nbsp;</span>
                Reported to Police: &nbsp;&nbsp; Yes
                <span class="text-center bx">{{ convertToParagraph(preAuthData.reportToPolice) ==
                    'REPORTTOPOLICEYES' ?'&#10003;':'&nbsp;'}}</span>
                No <span class="text-center bx">{{ convertToParagraph(preAuthData.reportToPolice) == 'REPORTTOPOLICENO'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;&nbsp;
                FIR no.:&nbsp;&nbsp;
                <span *ngIf="preAuthData.firNo ; else firNo">

                    &nbsp;&nbsp;
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.firNo)">{{n}}</span>
                </span>
                <ng-template #firNo>
                    <span class="text-center bx" *ngFor="let n of counter(7)">&nbsp;</span>
                </ng-template>

            </div>

        </div>

        <div class="row p-2">
            <div class="col text-start">
                Injury/Disease caused due to substance abuse/alcohol consumption:
                &nbsp;&nbsp;
                Yes<span class="text-center bx">{{convertToParagraph(preAuthData.substanceAbuse) =='SUBSTANCEABUSEYES'
                    ?'&#10003;':'&nbsp;'}}</span> &nbsp;&nbsp;
                No <span class="text-center bx">{{convertToParagraph(preAuthData.substanceAbuse) =='SUBSTANCEABUSENO'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;&nbsp;
                Test conducted to establish this, If yes attach reports:
                &nbsp;&nbsp; <span class="text-center bx">{{convertToParagraph(preAuthData.substanceAbuseTested)
                    ?'&#10003;':'&nbsp;'}}</span> Yes
                <span class="text-center bx">{{convertToParagraph(preAuthData.substanceAbuseTested)
                    ?'&#10003;':'&nbsp;'}}</span>
                No
            </div>

        </div>


        <div class="row p-2">
            <div class="col text-start">
                In case of maternity: &nbsp;
                &nbsp;G &nbsp;<span class="text-center bx">{{convertToParagraph(preAuthData.maternityType) == 'G'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;P &nbsp;<span class="text-center bx">{{convertToParagraph(preAuthData.maternityType) == 'P'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;L &nbsp;<span class="text-center bx">{{convertToParagraph(preAuthData.maternityType) == 'L'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;A &nbsp;<span class="text-center bx">{{convertToParagraph(preAuthData.maternityType) == 'A'
                    ?'&#10003;':'&nbsp;'}}</span>
                &nbsp;Expected date of Delivery:&nbsp;&nbsp;
                <span *ngIf="preAuthData.expectedDateOfDelivery ; else expectedDateOfDelivery">

                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.expectedDateOfDelivery)">{{n}}</span>
                </span>
                <ng-template #expectedDateOfDelivery>
                    <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>
            </div>

        </div>
        <div class="row pt-3">
            <div class="col">DETAILS OF THE PATIENT ADMITTED</div>
        </div>
        <hr>
        <div class="row">
            <div class="col">
                &nbsp;&nbsp;
                Date of admission:&nbsp;&nbsp;
                <span *ngIf="preAuthData.dateOfAdmission ; else nodateOfAdmission">

                    <span class="text-center bx" *ngFor="let n of dateOfAdmission">{{n}}</span>
                </span>
                <ng-template #nodateOfAdmission>
                    <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>
                
                &nbsp;&nbsp; Time of admission:&nbsp;&nbsp;
               
                <span *ngIf="preAuthData.timeOfAdmission ; else notimeOfAdmission">

                    <span class="text-center bx" *ngFor="let n of timeOfAdmission">{{n}}</span>
                </span>
                <ng-template #notimeOfAdmission>
                    <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>
                &nbsp;&nbsp; 
                This is<span class="text-center bx">&#10003;</span>&nbsp;&nbsp; /a planned hospitalization
                event&nbsp;&nbsp;<span class="text-center bx">&nbsp;</span>
            </div>
        </div>

        <div class="row p-2">
            <div class="col text-start">
                Expected number of days in hospital: &nbsp;&nbsp;
                &nbsp;&nbsp;
                <span *ngIf="preAuthData.daysInHospital; else daysInHospital">

                    &nbsp;&nbsp;<input class="text" size="5"
                        [value]="convertToParagraph(preAuthData.daysInHospital)">&nbsp;&nbsp; Days
                </span>
                <ng-template #daysInHospital>
                    <input class="text" size="5">&nbsp;&nbsp; Days
                </ng-template>
                <!-- <input type="text" class="text-center" [value]="daysInHospital">&nbsp;&nbsp;Days -->
                &nbsp;&nbsp;
                 Days in ICU: &nbsp;&nbsp;
                &nbsp;&nbsp;
                <span *ngIf="preAuthData.daysInICU; else daysInICU">

                    &nbsp;&nbsp;<input class="text" size="5"
                        [value]="convertToParagraph(preAuthData.daysInICU)">&nbsp;&nbsp; Days
                </span>
                <ng-template #daysInICU>
                    <input class="text" size="5">&nbsp;&nbsp; Days
                </ng-template>
                
                &nbsp;&nbsp; Room type: &nbsp;&nbsp;
                &nbsp;&nbsp;
                <span *ngIf="preAuthData.roomType; else roomType">

                    &nbsp;&nbsp;<input class="text" size="5"
                        [value]="convertToParagraph(preAuthData.roomType)">&nbsp;&nbsp; 
                </span>
                <ng-template #roomType>
                    <input class="text" size="5">&nbsp;&nbsp; 
                </ng-template>
                
                

            </div>

        </div>
        <hr>
        <div class="row p-2">
            <div class="col-5 text-start">
                Per Day Room Rent + Nursing & Service charges + Patient's Diet: &nbsp;&nbsp;
                <!-- Rs.<span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span> -->
            </div>
            <div class="col-3 text-start">
                <span *ngIf="preAuthData.roomRent ; else noRoomRent">
                    Rs.<span class="text-center bx"
                    *ngFor="let n of counter(getBlankSpaces(preAuthData.roomRent))">&nbsp;</span>
                <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.roomRent) ">{{n}}</span>
                </span>
                <ng-template #noRoomRent>
                   Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>

            </div>
            <div class="col-4 text-start">
                Mandatory past history of any chronic illness. If yes (since month/year)
            </div>
        </div>
        <div class="row p-2">
            <div class="col-5 text-start">
                Expected cost of investigation + diagnostics:

            </div>
            <div class="col-3 text-start">
                <span *ngIf="preAuthData.noinvCost ; else noinvCost">
                    Rs.<span class="text-center bx"
                    *ngFor="let n of counter(getBlankSpaces(preAuthData.invCost))">&nbsp;</span>
                <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.invCost)">{{n}}</span>
                </span>
                <ng-template #noinvCost>
                    Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>
               
            </div>
            <div class="col-4 text-start d-flex align-items-center">
                <div class="me-2">
                    <!-- {{preAuthData.historyDiabetes}} -->
                    <!-- {{convertToParagraph(preAuthData.historyDiabetes)}} -->
                    <span class="text-center bx">{{convertToParagraph(preAuthData.historyDiabetes) == 'HISTORYDIABETESYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                </div>
                <div>1. Diabetes </div>
                <div class="ms-2">
                    <span *ngIf="preAuthData.historyDiabetesSince; else nohistoryDiabetesSince">

                        &nbsp;&nbsp;
                        <ng-container *ngFor="let n of convertToArray(preAuthData.historyDiabetesSince)">
                            <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                        </ng-container>
                        
                    </span>
                   
                    <ng-template #nohistoryDiabetesSince>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                    <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                    </ng-template>
                 
                    <!-- <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span> -->

                   
                </div>
            </div>
        </div>
        <div class="row p-2">
            <div class="col-5 text-start">
                ICU Charges: &nbsp;&nbsp;
            </div>
            <div class="col-3 text-start ">
                <span *ngIf="preAuthData.icuRent ; else noicuRent">
                    Rs.<span class="text-center bx"
                    *ngFor="let n of counter(getBlankSpaces(preAuthData.icuRent))">&nbsp;</span>
                <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.icuRent)">{{n}}</span>
                </span>
                <ng-template #noicuRent>
                    Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                </ng-template>

               
            </div>
            <div class="col-4 text-start d-flex align-items-center">
                <div class="me-2">
                    <!-- {{convertToParagraph(preAuthData.historyHeartDisease)}} -->
                    <span class="text-center bx">{{convertToParagraph(preAuthData.historyHeartDisease) == 'HISTORYHEARTDISEASEYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                </div>
                <div>2. Heart Disease</div>
                <div class="ms-2">
                    <span *ngIf="preAuthData.historyHeartDiseaseSince; else nohistoryHeartDiseaseSince">

                        &nbsp;&nbsp;
                        <ng-container *ngFor="let n of convertToArray(preAuthData.historyHeartDiseaseSince)">
                            <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                        </ng-container>
                        
                    </span>
                   
                    <ng-template #nohistoryHeartDiseaseSince>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                    <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                    </ng-template>

                </div>
            </div>
        </div>
        <div class="row p-2">
            <div class="row">
                <div class="col-5 text-start">
                    OT Charges: &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.otCharges ; else nootCharges">
                        Rs.<span class="text-center bx"
                        *ngFor="let n of counter(getBlankSpaces(preAuthData.otCharges))">&nbsp;</span>
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.otCharges)">{{n}}</span>
                    </span>
                    <ng-template #nootCharges>
                        Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                    </ng-template>
                </div>
                <div class="col text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyHypertension) == 'HISTORYHYPERTENSIONYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>3. Hypertension</div>
                    <div class="ms-2">
                        <span *ngIf="preAuthData.historyHypertensionSince; else nohistoryHypertensionSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyHypertensionSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryHypertensionSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>
                        
                    </div>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-5 text-start">
                    Professional fees Surgeon + Anesthetist fees + Consultation Charges: &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.fees ; else nofees">
                       
                    Rs.<span class="text-center bx"
                    *ngFor="let n of counter(getBlankSpaces(preAuthData.fees))">&nbsp;</span>
                <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.fees) ">{{n}}</span>
                    </span>
                    <ng-template #nofees>
                        Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                    </ng-template>

                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyHypelipidemia) == 'HISTORYHYPELIPIDEMIAYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>4. Hyperlipidemias</div>
                    <div class="ms-2">
                        <span *ngIf="preAuthData.historyHypelipidemiaSince; else nohistoryHypelipidemiaSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyHypelipidemiaSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryHypelipidemiaSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>

                    </div>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-5 text-start">
                    Medicine + Consumables cost of implants :(specify if applicable) &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.hospitalExpenses ; else nohospitalExpenses">
                        Rs.<span class="text-center bx"
                        *ngFor="let n of counter(getBlankSpaces(preAuthData.hospitalExpenses))">&nbsp;</span>
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.hospitalExpenses)">{{n}}</span>
                        </span>
                        <ng-template #nohospitalExpenses>
                            Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                        </ng-template>

                   
                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyOsteoarthritis) == 'HISTORYOSTEOARTHITISYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>5. Osteoarthritis</div>
                    <div class="ms-2">
                       <span *ngIf="preAuthData.historyOsteoarthritisSince; else nohistoryOsteoarthritisSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyOsteoarthritisSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryOsteoarthritisSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>

                    </div>
                </div>

            </div>
            <div class="row p-2">
                <div class="col-5 text-start">
                    Other hospital expenses if any: &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.hospitalExpenses ; else nohospitalExpenses">
                        Rs.<span class="text-center bx"
                        *ngFor="let n of counter(getBlankSpaces(preAuthData.hospitalExpenses))">&nbsp;</span>
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.hospitalExpenses)">{{n}}</span>
                        </span>
                        <ng-template #nohospitalExpenses>
                           Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                        </ng-template>

                   
                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyAsthama) == 'HISTORYASTHAMAYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>6. Asthma/COPD/Bronchitis</div>
                    <div class="ms-2">
                       <span *ngIf="preAuthData.historyAsthamaSince; else nohistoryAsthamaSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyAsthamaSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryAsthamaSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>
                    </div>
                </div>

            </div>
            <div class="row p-2">
                <div class="col-5 text-start">
                    All inclusive package charges if any applicable: &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.packageCharges ; else nopackageCharges">
                        Rs.<span class="text-center bx"
                        *ngFor="let n of counter(getBlankSpaces(preAuthData.packageCharges))">&nbsp;</span>
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.packageCharges)">{{n}}</span>
                        </span>
                        <ng-template #nopackageCharges>
                           Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                        </ng-template>


                   
                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyCancer) == 'HISTORYCANCERYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>7. Cancer: </div>
                    <div class="ms-2">
                        <span *ngIf="preAuthData.historyCancerSince; else nohistoryCancerSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyCancerSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryCancerSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>

                    </div>
                </div>


            </div>
            <div class="row p-2">
                <div class="col-5 text-start">
                    Sum Total expected cost of hospitalization: &nbsp;&nbsp;
                </div>
                <div class="col-3 text-start">
                    <span *ngIf="preAuthData.sumExpectedCost ; else nosumExpectedCost">
                        Rs.<span class="text-center bx"
                        *ngFor="let n of counter(getBlankSpaces(preAuthData.sumExpectedCost))">&nbsp;</span>
                    <span class="text-center bx"
                        *ngFor="let n of convertToArray(preAuthData.sumExpectedCost)">{{n}}</span>
                        </span>
                        <ng-template #nosumExpectedCost>
                            Rs. <span class="text-center bx" *ngFor="let n of counter(8)">&nbsp;</span>
                        </ng-template>

                    
                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historySubstanceAbuse) == 'HISTORYSUBSTANCEABUSEYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>8. Alcohol or drug abuse </div>
                    <div class="ms-2">
                        <span *ngIf="preAuthData.historySubstanceAbuseSince; else nohistorySubstanceAbuseSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historySubstanceAbuseSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistorySubstanceAbuseSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>
                    </div>
                </div>

            </div>
            <div class="row p-2">
                <div class="col-5 text-start">

                </div>
                <div class="col-3 text-start">

                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">
                        <span class="text-center bx">{{convertToParagraph(preAuthData.historyStd) == 'HISTORYSTDYES'? '&#10003;' :
                        '&nbsp;'}}</span>
                    </div>
                    <div>9. Any HIV or STD / related ailments </div>
                    <div class="ms-2">
                        <span *ngIf="preAuthData.historyStdSince; else nohistoryStdSince">

                            &nbsp;&nbsp;
                            <ng-container *ngFor="let n of convertToArray(preAuthData.historyStdSince)">
                                <span class="text-center bx" *ngIf="n !== '/'">{{n}}</span>
                            </ng-container>
                            
                        </span>
                       
                        <ng-template #nohistoryStdSince>
                            <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        <span class="text-center bx" *ngFor="let n of counter(2)">&nbsp;</span>
                        </ng-template>
                    </div>
                </div>

            </div>
            <div class="row p-2">
                <div class="col-5 text-start">

                </div>
                <div class="col-3 text-start">

                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <div class="me-2">

                    </div>
                    <div>10. Any other ailment give details: </div>

                </div>
            </div>
            <div class="row p-2">
                <div class="col-5 text-start">

                </div>
                <div class="col-3 text-start">

                </div>
                <div class="col-4 text-start d-flex align-items-center">
                    <span *ngIf="preAuthData.historyMisc; else nohistoryMisc">

                        &nbsp;&nbsp;
                        <ng-container >
                            <textarea rows="3" cols="50">{{convertToParagraph(preAuthData.historyMisc)}}</textarea>
                        </ng-container>
                        
                    </span>
                   
                    <ng-template #nohistoryMisc>
                        <textarea rows="3" cols="50"></textarea>
                    </ng-template>
                   

                </div>
            </div>

            <div class="row p-2">
                <div class="col-3 hr-line">
                    &nbsp;
                </div>
                <div class="col-6 text-center">
                    <h4><strong>DECLARATION</strong>(PLEASE READ VERY CAREFULLY)</h4>
                </div>
                <div class="col-3 hr-line">

                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <strong>DECLARATION BY THE PATIENT/REPRESENTATIVE</strong>
                </div>

            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; We confirm having read understood and agreed to the declaration of this form
                </div>
            </div>

            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; Name of the treating doctor: &nbsp;&nbsp;
                    <span *ngIf="preAuthData.treatingDoctorName; else treatingDoctorName">
                        <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.treatingDoctorName)">{{ g
                            !== " " ? g : "&nbsp;" }}</span>
                        &nbsp; &nbsp; &nbsp;
                    </span>
                    <ng-template #treatingDoctorName>
                        <span class="text-center bx" *ngFor="let g of counter(20)">&nbsp;</span>
                    </ng-template>
    
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; Qualification: &nbsp;&nbsp; <span class="text-center bx"
                        *ngFor="let n of counter(16)">&nbsp;</span>
                    &nbsp;&nbsp; Registration No. with State code:: &nbsp;&nbsp; <span class="text-center bx"
                        *ngFor="let n of counter(10)">&nbsp;</span>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; a. I agree to allow the hospital to submit all original documents pertaining to
                    hospitalization to the Insurer/TPA after the discharge. I agree to sign on the Final Bill & the
                    Discharge Summary, before
                    my discharge
                </div>

            </div>

            <div class="row p-2">
                <div class="col">
                    b. Payment to hospital is governed by the terms and conditions of the policy. In case the Insurer /
                    TPA
                    is not liable to settle the hospital bill, I undertake to settle the bill as per the terms and
                    conditions of the policy.

                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <strong>DECLARATION BY THE PATIENT / REPRESENTATIVE</strong>
                </div>

            </div>

            <div class="row p-2">
                <div class="col">
                    <p>a. I agree to allow the hospital to submit all original documents pertaining to hospitalization
                        to
                        the
                        Insurer/TPA after the discharge. I agree to sign on the Final Bill & the Discharge Summary,
                        before
                        my discharge.</p>
                    <p>b. Payment to hospital is governed by the terms and conditions of the policy. In case the Insurer
                        /
                        TPA
                        is not liable to settle the hospital bill, I undertake to settle the bill as per the terms and
                        conditions
                        of the policy.</p>
                    <p>c. All non-medical expenses and expenses not relevant to current hospitalization and the amounts
                        over
                        &
                        above the limit authorized by the Insurer/TPA not governed by the terms and conditions of
                        the policy will be paid by me.</p>
                    <p>d. I hereby declare to abide by the terms and conditions of the policy and if at any time the
                        facts
                        disclosed by me are found to be false or incorrect I forfeit my claim and agree to indemnify the
                        insurer / TPA</p>
                    <p>e. I agree and understand that TPA is in no way warranting the service of the hospital & that the
                        Insurer / TPA is in no way guaranteeing that the services provided by the hospital will be of a
                        particular
                        quality or standard.</p>
                    <p>f. I hereby warrant the truth of the forgoing particulars in every respect and I agree that if I
                        have
                        made or shall make any false or untrue statement, suppression or concealment with respect to the
                        claim, my right to claim reimbursement of the said expenses shall be absolutely forfeited.</p>
                    <p>g. I agree to indemnify the hospital against all expenses incurred on my behalf, which are not
                        reimbursed by the Insurer/ TPA.</p>
                    <p>h. “I/We authorize Insurance Company/TPA to contact me/us through mobile/email for any update on
                        this
                        claim”</p>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; Patient’s / Insured’s name:: &nbsp;&nbsp;
                    <span class="text-center bx" *ngFor="let g of convertToArray(preAuthData.firstname)">{{ g !== " " ?
                        g : "&nbsp;" }}</span>
                    <span class="text-center bx">&nbsp;</span>
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData.lastname)">{{n}}</span>
                    <span class="text-center bx" *ngFor="let g of counter(remaining)">&nbsp;</span>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; Contact number:: &nbsp;&nbsp; 
                    <span *ngIf="preAuthData.contactNumber">
                        <span class="text-center bx"
                            *ngFor="let n of convertToArray(preAuthData.contactNumber)">{{n}}</span>
                    </span>
                    <span *ngIf="!preAuthData.contactNumber">
                        <span class="text-center bx" *ngFor="let n of counter(10)">&nbsp;</span>
                    </span>
                    &nbsp;&nbsp; Email ID: (Optional): &nbsp;&nbsp;
                    <span class="text-center bx" *ngFor="let n of convertToArray(preAuthData['emailid-email'])">{{n}}</span>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp; Patient signature: &nbsp;&nbsp; <textarea cols="50" rows="3"></textarea>
                    &nbsp;&nbsp; Date &nbsp;&nbsp; <span class="text-center bx"
                        *ngFor="let n of dateFormat(today)">{{n}}</span>
                    &nbsp;&nbsp;
                     Time &nbsp;&nbsp; <span class="text-center bx"
                     *ngFor="let n of timeFormat(today)">{{n}}</span>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <strong>HOSPITAL DECLARATION</strong>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <p>We have no objection to any authorized TPA / Insurance Company official verifying documents
                        pertaining to hospitalization.</p>
                    <p>All valid original documents duly countersigned by the insured / patient as per the checklist
                        below
                        will be sent to TPA/ Insurance Company within 7 days of the patient’s discharge.</p>
                    <p>We agree that TPA / Insurance Company will not be Liable to make the payment in the event of any
                        discrepancy between the facts in this form and discharge summary or other documents.</p>
                    <p>The patient declaration has been signed by the patient or by his representative in our presence.
                    </p>
                    <p>We agree to provide clarifications for the queries raised regarding this hospitalization and we
                        take
                        the sole responsibility for any delay in offering clarifications.</p>
                    <p>We will abide by the terms and conditions agreed in the MOU.</p>
                    <p>We confirm that no additional amount would be collected from the insured in excess of Agreed
                        Package
                        Rates except costs towards non-admissible amounts (including additional charges due to
                        opting higher room rent than eligibility choosing separate line of treatment which is not
                        envisaged/
                        considered in package).</p>
                    <p>We confirm that no recoveries would be made from the deposit amount collected from the lnsured
                        except
                        for costs towards non-admissible amounts (including additional charges due to opting
                        higher room rent than eligibility/ choosing separate line of treatment which is not
                        envisaged/considered in package).</p>
                    <p>In the event of unauthorized recovery of any additional amount from the Insured in excess of
                        Agreed
                        Package Rates, the authorized TPA / Insurance Company reserves the right to recover the
                        same from us (the Network Provider) and,/or take necessary action, as provided under the MOU or
                        applicable laws.</p>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <strong>DOCUMENTS TO BE PROVIDED BY THE HOSPITAL IN SUPPORT OF THE CLAIM</strong>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <p>Detailed Discharge Summary and all Bills from the hospital.</p>
                    <p>Cash Memos from the Hospitals / Chemists supported by proper prescription.</p>
                    <p>Receipts and Pathological Test Reports from Pathologists, Supported by note from the attending
                        Medical Practitioner / Surgeon recommending such pathological Tests.</p>
                    <p>Surgeon’s Certificate stating nature of Operation performed and Surgeon’s Bill and Receipt.</p>
                    <p>Certificates from attending Medical Practitioner / Surgeon that the patient is fully cured.</p>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp;Hospital Seal &nbsp;&nbsp;<textarea cols="50" rows="3"></textarea>
                    &nbsp;&nbsp;Doctor’s signature &nbsp;&nbsp;<textarea cols="50" rows="3"></textarea>
                </div>
            </div>
            <div class="row p-2">
                <div class="col">
                    &nbsp;&nbsp;Date &nbsp;&nbsp; <span class="text-center bx"
                    *ngFor="let n of dateFormat(today)">{{n}}</span>
                &nbsp;&nbsp;
                 Time &nbsp;&nbsp; <span class="text-center bx"
                 *ngFor="let n of timeFormat(today)">{{n}}</span>
                </div>
            </div>
            <div class="p-5">&nbsp;&nbsp;</div>
            <hr>
        </div>

    </div>