<div class="container p-3">
  <div class="container py-3 px-2">
    <div class="row text-end">
      <div class="col">
        <button
          mat-flat-button
          style="color: white; background: #8e0101"
          (click)="addKYP()"
        >
          Add KYP
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <!-- <mat-form-field
            
            appearance="fill"
            class="w-100"
          > -->
        <input
          type="search"
          (input)="handleUInput($event)"
          class="form-control"
          placeholder="Search policy"
          aria-label="Search"
        />

        <!-- <mat-select
              placeholder="Select a policy"
              [(ngModel)]="selectedPolicy"
            >
              <mat-option
                *ngFor="let policy of policyList"
                [value]="policy">
                {{parseId(policy.policyPath)}}
              </mat-option>
            </mat-select> -->
        <!-- </mat-form-field> -->
      </div>
      <div class="col-6">
        <button
          mat-flat-button
          style="color: white; background: #8e0101"
          (click)="getDetailsByPolicyNumber()"
        >
          Search
        </button>
      </div>
      <!-- <div class="col-lg-4">
          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div> -->
      <!-- <div class="col-lg-2 mt-2">
        <div class="example-flex-container">
         
           <button mat-flat-button style="color:white;background:#8e0101"
              (click)="clear()">
              Clear
            </button>
        </div>
      </div> -->
    </div>
  </div>
  <div class="container py-3 px-2">
    <div class="card px-3 py-4 justify-content-md-center">
      <div class="p-4">
        <mat-table class="" #table [dataSource]="dataSource">
          <ng-container matColumnDef="customerName">
            <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              (click)="viewCaseDetails(element)"
            >
              <ng-container *ngFor="let member of element.policyMembers">
                {{ member.memberName }}
              </ng-container>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="policyNumber">
            <mat-header-cell *matHeaderCellDef> Policy Number </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.policyNumber }}</mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="insuranceCompany">
            <mat-header-cell *matHeaderCellDef>
              Insurance Company
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.insuranceCompany }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="insuranceProduct">
            <mat-header-cell *matHeaderCellDef>
              Insurance Product</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element.insuranceProduct }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sumInsured">
            <mat-header-cell *matHeaderCellDef> Sum Insured </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.sumInsured }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                style="color: white; background: #8e0101"
                mat-flat-button
                (click)="viewCaseDetails(element)"
              >
                View
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[3, 5, 10]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
