import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-update-zone",
  //standalone: true,
  //imports: [],
  templateUrl: "./update-zone.component.html",
  styleUrl: "./update-zone.component.scss",
})
export class UpdateZoneComponent {
  zoneForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UpdateZoneComponent>
  ) {
    this.zoneForm = this._fb.group({
      category: ["", Validators.required],
      insuranceCompanyId: ["", [Validators.required]],
      zone: ["", [Validators.required]],
      insuranceEmailId: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.zoneForm.patchValue(this.data.id);
    this.zoneForm.controls["insuranceEmailId"].setValue(
      this.data.insuranceEmailId
    );
  }

  ngAfterViewInit(): void {}
  onFormSubmit() {
    this.adminService.updateZone(this.zoneForm.value).subscribe({
      next: (val: any) => {
        this.toastrService.success("Zone has been updated successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }
}
