<div class="container" *ngIf="user != undefined">
  <header class="pt-5">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <a href="http://bimagarage.com" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
        <img width="32" src="../assets/img/logo.png" />
      </a>

      <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
        <li>
          <a href="#" class="nav-link px-2 link-dark" routerLink="home" routerLinkActive="active"
            ariaCurrentWhenActive="page">Home</a>
        </li>
        <li>
          <div class="dropdown link-light">
            <button *ngIf="
                  isPrivileged([
                    'ADMIN',
                    'SUPERADMIN',
                    'IS_MOBILE_USER',
                    'PARTNER_USER',
                    'PARTNER_ADMIN',
                    'HNB_CLAIMS_ADMIN',
                    'HNB_POLICY_CONSULTANT'
                  ])
                " class="btn btn-light dropdown-toggle nav-link link-dark" type="button" routerLink="hobnob-home"
              routerLinkActive="active" ariaCurrentWhenActive="page" data-bs-toggle="dropdown" aria-expanded="false">
              <fa-icon style="color:rgb(183, 183, 183);" [icon]="faShareNodes"></fa-icon> HobNob
            </button>
            <ul class="dropdown-menu link-dark">
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="hobnob-policy"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Manage Policies
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="claim-now"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Claim Now / Consulting
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="claim-orders"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Claim-Orders
                </button>
              </li>
              <li>
                <button *ngIf="isPrivileged(['HOBNOB_AGENT'])" class="dropdown-item nav-link link-dark" type="button"
                  routerLink="claim-orders-agent" routerLinkActive="active" ariaCurrentWhenActive="page">
                  Agent Orders
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="hospital-locator"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Hospital Locator
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="health-cloud"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Health Cloud
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div class="dropdown link-light">
            <button *ngIf="
                  isPrivileged([
                    'MOBILE_USER',
                    'PARTNER_USER',
                    'PARTNER_ADMIN',
                    'HNB_CLAIMS_ADMIN',
                    'HNB_POLICY_CONSULTANT'
                  ])
                " class="btn btn-light dropdown-toggle nav-link link-dark" type="button" ariaCurrentWhenActive="page"
              data-bs-toggle="dropdown" aria-expanded="false">
              <fa-icon style="color:rgb(183, 183, 183);" [icon]="faNotesMedical"></fa-icon> ABHA
            </button>
            <ul class="dropdown-menu link-dark">
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="abha/create-id"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Get ABHA
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="abha/add"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Add Records
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="abha/view"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  View Records
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="abha/update"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Update Records
                </button>
              </li>

            </ul>
          </div>
        </li>
        <li>
          <a  class="nav-link px-2 link-dark" *ngIf="isPrivileged(['SUPERADMIN', 'ADMIN', 'CASHLESS_CLAIMS_ADMIN','CLAIMS_ADMIN','GENERAL_CLAIMS_OFFICER','CAN_MANAGE_CASHLESS_CLAIMS'])"
          (click)="viewClaimStatus()" routerLinkActive="active"><fa-icon
              style="color:rgb(183, 183, 183);" [icon]="faHouseMedicalCircleExclamation"></fa-icon> Claims Status</a>
        </li>
        <li>
          <div class="dropdown link-light">
            <button *ngIf="
                  isPrivileged([
                    'SUPERADMIN',
                    'ADMIN',
                    'CAN_VIEW_WORKFLOW'
                  ])
                " class="btn btn-light dropdown-toggle nav-link link-dark" type="button" data-bs-toggle="dropdown"
              routerLinkActive="active" ariaCurrentWhenActive="page" data-bs-toggle="dropdown" aria-expanded="false">
              <fa-icon style="color:rgb(183, 183, 183);" [icon]="faMicrochip"></fa-icon> Workflow
            </button>
            <ul class="dropdown-menu link-dark">
              <li>
                <a class="nav-link px-2 link-dark" *ngIf="
                      isPrivileged([
                        'CAN_VIEW_CASHLESS_PROCESS'
                      ])
                    " routerLink="cashless/create-case" routerLinkActive="active"
                  ariaCurrentWhenActive="page">Cashless</a>
              </li>
              <li>
                <a class="nav-link px-2 link-dark" *ngIf="
                      isPrivileged([
                        'SUPERADMIN',
                        'ADMIN',
                        'CAN_VIEW_REIMBURSEMENT_PROCESS'
                      ])
                    " routerLink="inbox" routerLinkActive="active" ariaCurrentWhenActive="page">Reimbursement</a>
              </li>

              <li>
                <a class="nav-link px-2 link-dark" *ngIf="
                      isPrivileged([
                        'SUPERADMIN',
                        'ADMIN',
                        'CAN_VIEW_EKYP_PROCESS'
                      ])
                    " routerLink="process-ekyp" routerLinkActive="active" ariaCurrentWhenActive="page">Process EKYP</a>
              </li>
              <li>
                <a class="nav-link px-2 link-dark" *ngIf="
                      isPrivileged([
                        'SUPERADMIN',
                        'ADMIN',
                        'CAN_VIEW_ONBOARDING_PROCESS'
                      ])
                    " routerLink="hospital/onboarding" routerLinkActive="active"
                  ariaCurrentWhenActive="page">Onboarding</a>
              </li>
              <li>
                <a class="nav-link px-2 link-dark" *ngIf="
                      isPrivileged([
                      'SUPERADMIN',
                      'ADMIN',
                      'CAN_VIEW_POLICY_CONSULTING_PROCESS',
                      ])
                    " routerLink="policy-consulting" routerLinkActive="active" ariaCurrentWhenActive="page">Policy
                  Consulting</a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a href="#" class="nav-link px-2 link-dark" routerLink="dashboard/management-dashboard"
            routerLinkActive="active" ariaCurrentWhenActive="page" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN' , 'CAN_VIEW_MANAGEMENT_DASHBOARD','CAN_VIEW_DASHBOARD'])"><fa-icon
              style="color:rgb(183, 183, 183);" [icon]="faLineChart"></fa-icon> Management Dashboard</a>
        </li>
        <li>
          <div class="dropdown link-light">
            <button class="btn btn-light dropdown-toggle nav-link link-dark" type="button" 
              routerLinkActive="active" ariaCurrentWhenActive="page" *ngIf="isPrivileged(['VENDOR_ADMIN', 'IS_VENDOR','VENDOR_SUPPORT_ADMIN','VENDOR_SUPPORT_AGENT'])"
              data-bs-toggle="dropdown" aria-expanded="false">
              Vendor Portal
            </button>
            <ul class="dropdown-menu link-dark">
              <li *ngIf="isPrivileged(['SUPERADMIN','ADMIN', 'VENDOR_ADMIN', 'IS_VENDOR'])">
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="product"
                  routerLinkActive="active" ariaCurrentWhenActive="page">
                  Manage Products
                </button>
              </li>
              <li *ngIf="isPrivileged(['SUPERADMIN','ADMIN', 'VENDOR_ADMIN', 'IS_VENDOR'])">
                <button class="dropdown-item nav-link link-dark" routerLink="vendor-order" type="button"
                  ariaCurrentWhenActive="page">
                  Manage Orders
                </button>
              </li>

              <!-- CODE BELOW SHOULD NOT BE REMOVED -->
              <!--<li *ngIf="
                    isPrivileged([
                      'SUPERADMIN',
                      'ADMIN',
                      'VENDOR_ADMIN', 'IS_VENDOR',
                      'VENDOR_SUPPORT_ADMIN',
                      'VENDOR_SUPPORT_AGENT'
                    ])
                  ">
                <button class="dropdown-item nav-link link-dark" routerLink="vendor-support" type="button"
                  ariaCurrentWhenActive="page">
                  Vendor Support
                </button>
              </li>-->
            </ul>
          </div>
        </li>
        <li>
          <a href="#" class="nav-link px-2 link-dark" routerLink="partner" routerLinkActive="active"
            ariaCurrentWhenActive="page" *ngIf="isPrivileged(['PARTNER_USER', 'PARTNER_ADMIN'])">Partner Portal</a>
        </li>
        <li>
          <a href="#" class="nav-link px-2 link-dark" routerLink="campaign" routerLinkActive="active"
            ariaCurrentWhenActive="page" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN','SALES_ADMIN'])"><fa-icon
              style="color:rgb(183, 183, 183);" [icon]="faVolumeLow"></fa-icon> Campaign Management </a>
        </li>
        <li>
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle nav-link link-dark" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <fa-icon style="color:rgb(183, 183, 183);" [icon]="faScrewdriverWrench"></fa-icon> Admin
            </button>
            <ul class="dropdown-menu link-dark">
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manageUser"
                  routerLinkActive="active" ariaCurrentWhenActive="page" *ngIf="
                      isPrivileged([
                        'VENDOR_ADMIN',
                        'ADMIN',
                        'SUPERADMIN',
                        'PARTNER_ADMIN',
                        'HOSPITAL_ADMIN'
                      ])
                    ">
                  Manage Users
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manageRole" *ngIf="
                      isPrivileged([
                        'ADMIN',
                        'SUPERADMIN',
                        'SALES_ADMIN'
                      ])
                    ">
                  Manage Roles
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manageOrg"
                  routerLinkActive="active" ariaCurrentWhenActive="page" *ngIf="
                      isPrivileged([
                        'ADMIN',
                        'SUPERADMIN'
                      ])
                    ">
                  Manage Org
                </button>
              </li>
              
              <li *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN', 'CASHLESS_CLAIMS_ADMIN'])">
                <hr class="dropdown-divider">
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-cases"
                  routerLinkActive="active" ariaCurrentWhenActive="page"
                  *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_CASE_CASHLESS'])">
                  Manage Cases
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-ekyp"
                  routerLinkActive="active" ariaCurrentWhenActive="page"
                  *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_EKYP'])">
                  Manage eKYP
                </button>
              </li>
              <li *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN', 'CASHLESS_CLAIMS_ADMIN'])">
                <hr class="dropdown-divider">
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-hospitals"
                  routerLinkActive="active" ariaCurrentWhenActive="page"
                  *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_HOSPITAL'])">
                  Manage Hospitals
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-insurance-companies"
                  *ngIf="
                  isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_IC'])
                    ">
                  Manage Insurance Companies
                </button>
              </li>

              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-tpa"
                  routerLinkActive="active" ariaCurrentWhenActive="page" *ngIf="
                  isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_TPA'])
                    ">
                  Manage TPA
                </button>
              </li>

              <li *ngIf=" isPrivileged(['ADMIN', 'SUPERADMIN', 'CAN_MANAGE_TPA'])">
                <hr class="dropdown-divider">
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-hospital-products"
                  routerLinkActive="active" ariaCurrentWhenActive="page"
                  *ngIf=" isPrivileged(['ADMIN', 'SUPERADMIN', 'SALES_ADMIN'])">
                  Manage BG Products
                </button>
              </li>

              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manageProduct"
                  routerLinkActive="active" *ngIf="isPrivileged(['SUPERADMIN','ADMIN','SALES_ADMIN'])" ariaCurrentWhenActive="page">
                  Manage Hobnob Products
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manageCoupon"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN','SALES_ADMIN'])" ariaCurrentWhenActive="page">
                  Manage Coupons
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-category-master"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN'])" ariaCurrentWhenActive="page">
                  Manage Category Master
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-task-calculation"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN'])" ariaCurrentWhenActive="page">
                  Manage Task Calculation
                </button>
              </li>
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-workflow"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN','CAN_VIEW_WORKFLOW'])" ariaCurrentWhenActive="page">
                  Manage Workflows
                </button>
              </li>

              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-zone"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN'])" ariaCurrentWhenActive="page">
                  Manage Zone
                </button>
              </li>

              
              <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="manage-email-lookup"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN'])" ariaCurrentWhenActive="page">
                  Manage Email LookUp
                </button>
              </li>

              <!-- <li>
                <button class="dropdown-item nav-link link-dark" type="button" routerLink="email-conf"
                  routerLinkActive="active" *ngIf="isPrivileged(['ADMIN', 'SUPERADMIN'])" ariaCurrentWhenActive="page">
                  Manage Email Config
                </button>
              </li> -->
            </ul>
          </div>
        </li>
        <li>
          <a href="#" class="nav-link px-2 link-dark" routerLink="support" routerLinkActive="active"
            ariaCurrentWhenActive="page">Support <fa-icon style="color:rgb(183, 183, 183);"
              [icon]="faPhoneVolume"></fa-icon></a>
        </li>
      </ul>
      <div class="row">
        <div class="col d-flex  justify-content-end">
          <div class="text-end">
            <button type="button" class="btn btn-outline-primary me-2" routerLink="login" routerLinkActive="active"
              ariaCurrentWhenActive="page" *ngIf="user == undefined">
              Login
            </button>
            <!--<button type="button" class="btn btn-primary" *ngIf="user == undefined">Sign-up</button>-->
          </div>
          <div class="dropdown text-end" *ngIf="user != undefined">
            <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="img" *ngIf="img" alt="" width="32" height="32"
                style="border-color:rgb(0, 157, 255);border-width:thin;border-style:solid;"
                class="rounded-5 rounded-circle" />

              <fa-icon class="fa-xl text-danger" *ngIf="!img" [icon]="faUserShield"></fa-icon>
              <span> {{ user.firstName }} {{ user.lastName }} </span>
            </a>
            <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
              <!-- <li><a class="dropdown-item" href="#">Settings</a></li> -->
              <li>
                <a class="dropdown-item" href="#" routerLink="profile">Profile</a>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li><a class="dropdown-item" (click)="logout()">Sign out</a></li>
            </ul>
          </div>
        </div>
      </div>


    </div>
    <div class="row pt-3">
      <div class="col ">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item fs-6"><a [href]="pathUrl"><i> {{label}}&nbsp;</i></a></li>
            <li class=""> &nbsp;<fa-icon [icon]="faLink">&nbsp; </fa-icon> </li>
          </ol>
        </nav>
      </div>
      <div class="col d-flex pb-2 justify-content-end">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item "><a href="#" (click)="setLandingPage()"><i> Set my home</i></a></li>
            <li class=""> &nbsp;<fa-icon [icon]="faHomeUser">&nbsp; </fa-icon> </li>
          </ol>
        </nav>
          <!--<button style="color:#8e0101;margin-left:1px" mat-flat-button (click)="setLandingPage()">Set my home <mat-icon>home</mat-icon></button>-->
        
      </div>

    </div>
  </header>
</div>
<div class="bg-light pb-3">
  <router-outlet></router-outlet>
</div>
<footer class=" py-2 bg-dark">
  <div class="container">
    <!--<footer class="footer text-center text-white" style="background-color: #21081a;">-->
    <!-- Copyright -->
    <div class="text-center p-3 text-white">
      © 2023 Copyright:
      <a class="text-white" href="https://bimagarage.com/">BG Networks Pvt Ltd</a>
      <p>
        is not regulated by IRDA and does not have any tie up with Insurance
        Company or Third party Administrator.
      </p>
    </div>
  </div>
  <!-- Copyright -->
</footer>