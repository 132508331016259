<div class="container p-3">
  <div mat-dialog-title>
    <div class="row">
      <div class="col-8 d-flex justify-content-start">
        <h1>View Mail Conf</h1>
      </div>
      <div class="col-4 d-flex justify-content-end" >
        <button  style="color:white;background:#8e0101;" (click)="add(data)" mat-mini-fab><mat-icon>add</mat-icon></button>
        
        &nbsp;<button  mat-dialog-close style="color:white;background:#8e0101;" mat-mini-fab><mat-icon>cancel</mat-icon></button>
        
      </div>
    </div>
  </div>

  <mat-table class="" [dataSource]="dataSource" matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="hospitalOrgId">
      <mat-header-cell *matHeaderCellDef> HospitalOrgId </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.hospitalOrgId }}
      </mat-cell>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="payorType">
      <mat-header-cell *matHeaderCellDef> Payor Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.payorType }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="receivingEmailHost">
      <mat-header-cell *matHeaderCellDef> Receiving EmailHost </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.receivingEmailHost }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="receivingEmailId">
      <mat-header-cell *matHeaderCellDef>Receiving EmailId </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.receivingEmailId }}
      </mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="receivingEmailStoreType">
      <mat-header-cell *matHeaderCellDef>
        Receiving EmailStoreType
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.receivingEmailStoreType }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="update">
      <mat-header-cell
        style="background: rgb(241, 241, 241)"
        *matHeaderCellDef
        mat-sort-header
      >
        Update
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button style="color: #8e0101" (click)="update(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <br />

  <div
    class="alert alert-danger mt-2 pt-1"
    role="alert"
    *ngIf="message != undefined"
  >
    {{ message }}
  </div>
</div>
