<!-- <div mat-dialog-title>
    <h1>Update Role</h1>
</div> -->
<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Update Role</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
  </div>
</div>
<form [formGroup]="roleForm" (ngSubmit)="onFormSubmit()">
    <div mat-dialog-content class="content">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Id</mat-label>
                <input matInput type="text" placeholder="id"  formControlName="id" readonly >
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Role Name</mat-label>
                <input matInput type="text" placeholder="Role Name" formControlName="roleName" readonly>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Role Description</mat-label>
                <input matInput placeholder="Role Description" formControlName="roleDescription">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Tags</mat-label>
              <input matInput type="text" placeholder="Tags" formControlName="tags" >
          </mat-form-field>
        
        <!-- <div class="row">
            <mat-form-field appearance="outline">
              <mat-label>Privileges</mat-label>
              <mat-select formControlName="privileges" [(ngModel)]="selectedType" name="privelegedata" multiple>
                <mat-option *ngFor="let privelegedata of data.rolePrivileges" [value]="privelegedata.id.privilegeId">
                    {{privelegedata.id.privilegeId}} 
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- <div class="row">
            <mat-form-field appearance="outline">
              <mat-label>Existing Privileges</mat-label>
              <mat-select formControlName="privileges"  name="privelegedata" multiple [(ngModel)]="selectedType">
                <mat-option  *ngFor="let privelegedata of filteredArr" [value]="privelegedata.id">
                    {{privelegedata.privilege}} 
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
            <mat-form-field appearance="outline">
              <mat-label>Select new Privileges</mat-label>
              <mat-select formControlName="privileges"  name="privelegedata" multiple [(ngModel)]="selectedType">
                <mat-option *ngFor="let privelegedata of priveleges" [value]="privelegedata.id">
                    {{privelegedata.privilege}} 
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    </div>
    <div mat-dialog-actions class="action">
        <button mat-raised-button type="button" [mat-dialog-close]="false">Cancel</button>
        <button mat-raised-button color="primary" type="submit">{{data ? 'Update': 'Save'}}</button>
    </div>
</form>