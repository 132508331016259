import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Case } from 'src/app/model/case';
import { CustomerService } from 'src/app/services/customer.service';
import {parse} from 'angular-html-parser';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ClaimOrdersDetailsComponent } from '../../customer/hobnob/claim-orders/claim-orders-details/claim-orders-details.component';
import { ClaimDetailsComponent } from './modal/claim-details/claim-details.component';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit{
  
  @ViewChild("userHtml", { static: false }) userHtml: any;

  caseId = new FormControl('', [Validators.required]);
  cases: Case[] = [];
  displayedColumns: string[] = ['caseId', 'status', 'hospitalEmail', 'attachments', 'receivedDate','information'];
  dataSource!: MatTableDataSource<Case>;
  constructor(
    private customerService: CustomerService, 
    private cd: ChangeDetectorRef,
    private _dialog: MatDialog,
    private searchService:SearchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }
  ngOnInit(){
    console.log(JSON.stringify(this.data));
    this.caseId.setValue(this.data.variables.firstname +" "+this.data.variables.lastname);
    this.getCaseStatus();
  }

  error: any;
  message: any;
  loading: any;
  rowNum:any = 0;
  size:any = 100;
  getCaseStatus() {
    this.loading = true;
    this.customerService.getCaseStatus(this.caseId.value, this.rowNum, this.size).pipe(first()).subscribe({
      next: (data: Case[]) => {
        this.cases = data;
        this.dataSource = new MatTableDataSource(this.cases);
        this.loading = false;
      },
      error: error => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      }
    })

  }

  getClaimEmails() {
    this.loading = true;
    this.searchService.searchClaimEmails(this.caseId.value).pipe(first()).subscribe({
      next: (data: Case[]) => {
        this.cases = data;
        this.dataSource = new MatTableDataSource(this.cases);
        this.loading = false;
      },
      error: error => {
        this.error = error.error;
        this.message = undefined;
        console.log(error);
        this.loading = false;
      }
    })

  }

  getErrorMessage() {
    if (this.caseId.hasError('required')) {
      return 'You must enter a value';
    }

    return this.caseId.hasError('caseId') ? 'Not a valid caseId' : '';
  }

  parseJson(inputData:string){
    var dd = JSON.parse(inputData);
//console.log(dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>',''))
    const hh = dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>','');
    return "<p>"+hh+"</p>";
  }

  parseAttachements(inputData:string){
    var dd = JSON.parse(inputData);
//console.log(dd.content.replaceAll('\\','').replaceAll('\r','').replaceAll('<html>','').replaceAll('<body>','').replaceAll('</html>','').replaceAll('</body>',''))
    const hh = dd.attachments;
    return hh;
  }

  getHTMLData(inputData:any){
    const {rootNodes, errors} = parse(inputData);
    //console.log(rootNodes.values);
  }

  openViewHtml(element: any,attachments:any) {
    const dialogRef = this._dialog.open(ClaimDetailsComponent, {
      disableClose:true,
      data: { htmlData: element, attachments:attachments },

    });
    dialogRef.afterClosed().subscribe({
      next: (val: any) => {
        if (val) { }
      },
    });
  }


}
