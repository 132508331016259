<mat-dialog-content class="mat-typography">
  <div class="container py-1">
    <div mat-dialog-title>
      <div class="row m-2 justify-content-md-center">
        <div class="col p-3">
          <div class="card">
            <div class="card-header text-center bg-light">Upload file</div>
            <div class="card-body">
              <div
                class="row pt-2 text-center"
                *ngIf="
                  selectedCategory == 'LEGAL_AGREEMENT' ||
                  selectedCategory == 'SOC_DOCUMENT'
                "
              >
                <h4>{{ categoryList[0]?.categoryDescription }}</h4>
              </div>

              <div class="row pt-2">
                <select
                  class="form-select"
                  required="true"
                  [(ngModel)]="selectedCategory"
                  [compareWith]="compareWithFn"
                  (ngModelChange)="enableDate()"
                >
                  <option
                    *ngFor="let category of categoryList"
                    [value]="category.id.categoryName"
                  >
                    {{ category.id.categoryName }}
                  </option>
                </select>
              </div>

              <div class="row pt-2">
                <label class="btn btn-default p-0">
                  <input
                    class="form-control"
                    type="file"
                    accept="application/pdf,image/png,image/jpeg,image/jpg"
                    (change)="selectFile($event)"
                  />
                </label>
              </div>
              <div class="row pt-2" *ngIf="dateEnabled">
                <label class="normal-font">Valid From </label>
                <input
                  class="form-control"
                  type="date"
                  [(ngModel)]="validFrom"
                />
              </div>
              <div class="row pt-2" *ngIf="dateEnabled">
                <label class="normal-font">Valid To</label>
                <input
                  class="form-control"
                  type="date"
                  [(ngModel)]="validTo"
                  (ngModelChange)="dateValidationCheck()"
                />
              </div>
              <div *ngIf="dateEnabled" style="color: red; font-size: small">
                <p *ngIf="dateValidation">
                  Valid To date can not be before Valid From date
                </p>
              </div>
              <div class="row pt-2">
                <div class="col text-end">
                  <button
                    class="btn btn-success btn-sm"
                    [disabled]="selectedFiles == null || clicked"
                    (click)="uploadFile()"
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div *ngIf="currentFile" class="progress my-3">
                <div
                  class="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  attr.aria-valuenow="{{ progress }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button (click)="close()">Close</button>
</mat-dialog-actions>
