<!-- <div>
    <h1 mat-dialog-title>User Roll Details</h1>
    <div mat-dialog-content *ngFor="let data of userrolesdata">
        <p>RoleName : {{data.roleName}}</p>
        <p>RoleDescription : {{data.roleDescription}}</p>
        <p>OrgType : {{data.orgType}}</p>


    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
</div> -->

<div mat-dialog-title>
  <div class="row">
    <div class="col-8 d-flex justify-content-start">
      <h1>Manage users Role!</h1>
    </div>
    <div class="col-4 d-flex justify-content-end" mat-dialog-close>
      <h4>
        <i class="px-3" style="color: lightgray"
          >* marked fields are mandatory
        </i>
      </h4>
      <mat-icon color="accent">cancel</mat-icon>
    </div>
    <div class="col-12 d-flex justify-content-end">
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="openAddRolesForm()"
      >
        Add
      </button>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class=" p-4">
    <mat-table [dataSource]="userRolesData" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Role Id</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.roleId }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="roleName">
        <mat-header-cell *matHeaderCellDef>Role Name</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.roleName }}</mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="roleDescription">
        <mat-header-cell *matHeaderCellDef>Role Description</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.desc }}
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <!-- <ng-container matColumnDef="orgType">
        <mat-header-cell *matHeaderCellDef>Org Type</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.orgType }}</mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <!-- <button mat-icon-button color="primary">
                    <mat-icon>edit</mat-icon>
                  </button> -->
          <!-- <button mat-icon-button color="primary">
                    <mat-icon>add</mat-icon>
                  </button> -->
          <button
            mat-icon-button
            color="primary"
            (click)="deletUsereRoles(row)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <!-- <button mat-icon-button color="primary" (click)="openAddRolesForm()">
                    <mat-icon>add</mat-icon>
                  </button> -->
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="accent" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>