<div class="container p-3">

    <div class="h1">Business Dashboard</div>
    <div [formGroup]="dateForm">
        <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="fromDate" (dateChange)="onChangeEvent($event)">
            <mat-hint>MM/DD/YYYY</mat-hint>

            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="isPrivileged(['CAN_VIEW_HOSPITAL'])">
            <mat-label>Select Hospital</mat-label>
            <mat-select [(value)]="selectedHospital" (selectionChange)="hospitalSelected($event)">
                <mat-option value="ALL" *ngIf="!isPrivileged(['IS_HOSPITAL_USER'])">ALL</mat-option>
                @for (hospital of hospitals; track hospital) {
                <mat-option [value]="hospital.hospitalOrgId">{{hospital.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isPrivileged(['CAN_VIEW_HOSPITAL'])">
            <mat-label>Hospital Name</mat-label>
            <input matInput [value]="selectedHospital.name" disabled="true">
        </mat-form-field>
    </div>
    <hr>
    <div class="row" *ngIf="dashboardData.length >0">
        <strong>{{dashboardData[0].processName | uppercase}}</strong>
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4"><span class="badge fs-2 " style="background:rgb(254, 215, 87)"><fa-icon
                                    class="fa-xl" [icon]="faClock"></fa-icon></span></div>
                        <div class="col text-end">
                            <h5>Pending settlements</h5>
                            <h2 class="text-secondary">{{dashboardData[0].header.pendingSettlement
                                }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4"><span class="badge fs-2 " style="background:rgb(87, 209, 254)"><fa-icon
                                    class="fa-xl" [icon]="faFlagCheckered"></fa-icon></span></div>
                        <div class="col text-end">
                            <h5>Settlements completed</h5>
                            <h2 class="text-secondary">{{dashboardData[0].header.settlementCompleted}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4"><span class="badge fs-2 " style="background:rgb(254, 154, 87)"><fa-icon
                                    class="fa-xl" [icon]="faClock"></fa-icon></span></div>
                        <div class="col text-end">
                            <h5>Partial Settlements</h5>
                            <h2 class="text-secondary">{{dashboardData[0].header.
                                partialSetlement}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4"><span class="badge fs-2 " style="background:rgb(254, 87, 115)"><fa-icon
                                    class="fa-xl" [icon]="faFilterCircleXmark"></fa-icon></span></div>
                        <div class="col text-end">
                            <h5>Rejected Cases</h5>
                            <h2 class="text-secondary">{{dashboardData[0].header.rejectedCases}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="h1 pt-5">Operational Dashboard</div>
    <hr>
    <div class="row" *ngIf="dashboardData.length >0">
        <div class="col-4 p-2 " *ngFor="let element of dashboardData">
            <div class="card ">
                <div class="card-header" style="background:white;">
                    <div class="row">
                        <div class="col">
                            <h3 class="card-title text-secondary"><strong>{{element.processName | camelCaseToSpaces |
                                    uppercase}} </strong></h3>
                        </div>
                        <div class="col-3 text-light text-end" (click)="analyze(element.processName) "><span
                                class="badge fs-6 " style="background:rgb(191, 191, 191)"><fa-icon class="fa-xl"
                                    [icon]="faMagnifyingGlassChart"></fa-icon></span></div>

                        <!--<div class="col-2 text-end fa-xl text-danger" (click)="analyze(element.processName)">
                            <fa-icon [icon]="faMagnifyingGlassChart"></fa-icon>
                        </div>-->
                    </div>

                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col ">
                            <div class="row">
                                <div class="col ">
                                    <h5 class="card-title  text-center">Completed</h5>
                                </div>

                                <div class="col  text-center align-middle">
                                    <h1 class="text-light badge" style="background:rgb(159, 213, 114)"><span
                                            class="badge">{{element.processCompleted?element.processCompleted:'0'}}</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col ">
                                    <h5 class="card-title  text-center">Underway</h5>
                                </div>
                                <div class="col  text-center align-middle">
                                    <h1 class="text-light bg-warning badge"><span
                                            class="badge">{{element.processInProgress?element.processInProgress:'0'}}</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col ">
                                    <h5 class="card-title text-center">Avg Duration (Mins)</h5>
                                </div>
                                <div class="col  text-center align-middle">
                                    <h1 class="text-light badge" style="background:rgb(140, 210, 254)"><span
                                            class="badge">{{calculateAvgDuration(element.completedInstances ) |
                                            number:'4.0-0'}}</span></h1>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>


</div>