import { Component, OnInit, signal } from '@angular/core';
import { AgChartOptions } from 'ag-charts-community';
import { first } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import { faMagnifyingGlassChart, faClock, faFlagCheckered, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { UserModalComponent } from '../modal/user-modal/user-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CashflowService } from 'src/app/services/cashflow.service';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/services/authService';
@Component({
  selector: 'app-management-dashboard',
  templateUrl: './management-dashboard.component.html',
  styleUrls: ['./management-dashboard.component.scss']
})
export class ManagementDashboardComponent implements OnInit {

  selectedHospital: any;
  hospitals: any = [];
  dt: any = new Date();
  dateForm: FormGroup;
  user:any;
  org:any;
  public options: AgChartOptions;
  faMagnifyingGlassChart = faMagnifyingGlassChart;
  faClock = faClock;
  faFlagCheckered = faFlagCheckered;
  faFilterCircleXmark = faFilterCircleXmark;
  constructor(
    private _fb: FormBuilder,
    public dashboardService: DashboardService,
    public dialog: MatDialog,
    private cashflowService: CashflowService,
    private authService:AuthService,
    private cashlessService: CashflowService
  ) {
    this.dt.setMonth(this.dt.getMonth() - 3);
    this.dateForm = this._fb.group({

      fromDate: [this.dt, Validators.required],
    });
    this.options = {
      data: [{ asset: "data", amount: 3 }],
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          calloutLabelKey: 'asset',
          sectorLabelKey: 'amount',
          sectorLabel: {
            color: 'white',
            fontWeight: 'bold',
          },
        },
      ],
    };

    this.authService.user.subscribe((x: any) => {
      this.user = x;
      //console.log(this.user);
      if (this.user && this.isPrivileged(["IS_HOSPITAL_USER"])) {
       
        this.org = this.user.orgId;
      }
    });
  }
  ngOnInit(): void {
   
    this.getDashboard(this.dt.toISOString(), "");
    this.getOnboardedHospitals();
  }


  onChangeEvent(event: { value: any; }) {
    //console.log(event);
    this.dt = new Date(event.value);
    //console.log(new Date(event.value));
    if (this.hospitalOrgId != undefined) {
      this.getDashboard(this.dt.toISOString(), this.hospitalOrgId);
    } else if (this.hospitalOrgId == undefined || this.selectedHospital == "ALL") {
      this.getDashboard(this.dt.toISOString(), "");
    }

  }

  hospitalSelected(event: MatSelectChange) {
    console.log(event.value);
    this.hospitalOrgId = event.value;
    if(this.hospitalOrgId == "ALL"){
      this.getDashboard(this.dt.toISOString(), "");
    }else{
      this.getDashboard(this.dt.toISOString(), this.hospitalOrgId);
    }
    
  }

  isRoles(role: string[]) {
    const roles: any[] = this.user.roles;

    var dd = roles.some((x: string) => role.includes(x));
    return dd;
  }
  isPrivileged(privilege: string[]) {
    const privileges: any[] = this.user.privileges;
    var dd = privileges.some((x: string) => privilege.includes(x));
    //console.log("is Privileged "+ this.user.privileges);
    return dd;
  }



  hospitalOrgId: any;
  dashboardData: any[] = [];
  error: any;
  message: any;
  loading: any;
  getDashboard(dt: any, hospitalOrgId: any) {
    this.dashboardService
      .getDashboard("management", "BG", dt, hospitalOrgId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.dashboardData = data.processInstanceData;
          //console.log(JSON.stringify(data));
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  getOnboardedHospitals() {
    this.cashflowService
      .getOnboardedHospitals()
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.hospitals = data;
          console.log(JSON.stringify(data));
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }


  calculateAvgDuration(completedInstances: any[]) {
    let totalDuration = 0;
    for (var x = 0; x < completedInstances.length; x++) {
      totalDuration += completedInstances[x].duration;
    }
    if (totalDuration > 0) {
      return (totalDuration / (1000 * completedInstances.length * 60));
    } else {
      return 0;
    }
  }

  performance: any;

  analyze(processName: any) {
    this.dashboardService
      .getUserPerformance(processName, this.dt.toISOString())
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //console.log(data);
          this.performance = data;
          console.log("Performance " + JSON.stringify(data));
          this.openDialog()
        },
        error: (error) => {
          this.error = error.error;
          this.message = undefined;
          console.log(error);
          this.loading = false;
        },
      });
  }

  openDialog() {
    this.dialog.open(UserModalComponent, {
      disableClose: true,
      data: this.performance,
      width: '80%',
      height: '80%'
    });
  }
}
