import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { first } from "rxjs";
import { AdminService } from "src/app/services/admin.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-zone",
  //standalone: true,
  //imports: [],
  templateUrl: "./add-zone.component.html",
  styleUrl: "./add-zone.component.scss",
})
export class AddZoneComponent {
  zoneForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private datePipe: DatePipe,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AddZoneComponent>
  ) {
    this.zoneForm = this._fb.group({
      // id: '',
      category: ["", Validators.required],
      insuranceCompanyId: ["", [Validators.required]],
      zone: ["", [Validators.required]],
      insuranceEmailId: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  deployedAllProcesses: any;

  onFormSubmit() {
    var data = {
      insuranceEmailId: this.zoneForm.value.insuranceEmailId,
      category: this.zoneForm.value.category,
      insuranceCompanyId: this.zoneForm.value.insuranceCompanyId,
      zone: this.zoneForm.value.zone,
      dateCreated: "",
      createdBy: "",
      dateUpdated: "",
      updatedBy: "",
    };

    this.adminService.addZone(data).subscribe({
      next: (val: any) => {
        this.toastrService.success("Zone has been added successfully");
        this._dialogRef.close(true);
      },
      error: (err: any) => {
        console.error(err);
        //this.toastrService.error("Error");
        this.toastrService.error(JSON.stringify(err.error.error));
      },
    });
  }
}
