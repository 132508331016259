import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { CashflowService } from 'src/app/services/cashflow.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-standard-preauth-form',
  templateUrl: './standard-preauth-form.component.html',
  styleUrl: './standard-preauth-form.component.scss'
})
export class StandardPreauthFormComponent implements OnInit {
  @ViewChild('preauthContent', { static: false }) contentToConvert!: ElementRef;
  preAuthData: any;
  dob: any;
  insuranceMediaId: any;
  insurance: any;
  error: any;
  today = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cashlessService: CashflowService
  ) {

  }
  ngOnInit(): void {
    this.getPreAuthData(this.data.caseId);
  }

  getPreAuthData(id: any) {
    this
      .cashlessService.getPreauthDetails(id)
      .subscribe({
        next: (data: any) => {

          this.preAuthData = data;
          this.dataConversion();
          this.calculateAge(this.preAuthData.dob);
          this.setInsuranceMediaId(this.preAuthData);
          // console.log(JSON.stringify(this.preAuthData));

        },
        error: (err: any) => {
          console.error(err);
          //this.toastrService.error("Error");
        },
      });
  }
  ageFormat(data: string) {
    return Object.assign([], data);
  }

  convertToParagraph(data: any) {
    return this.removeQuotes(data);
  }

  removeQuotes(data: any) {
    // console.log(data);
    if (data) {
      const trasnformedData = data.replaceAll("\"", "");
      return trasnformedData.toUpperCase();
    } else {
      return '';
    }

  }
  dataConversion() {
    //this.fnameA = Object.assign([], this.removeQuotes(this.preAuthData?.firstname));
    //this.lnameA = Object.assign([], this.removeQuotes(this.preAuthData?.lastname));
    //this.age = this.removeQuotes(this.preAuthData?.age);
    //console.log(this.age);
    //this.remaining = (40 - (this.fnameA.length + this.lnameA.length));

    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(this.removeQuotes(this.preAuthData.dob), 'ddMMYYYY', 'UTC');
    // console.log(formattedDate);
    this.dob = formattedDate
    // this.mediclaimExists = this.removeQuotes(this.preAuthData.mediclaimExists);

    // this.dateOfAdmission =Object.assign([], datePipe.transform(this.removeQuotes(this.preAuthData.dateOfAdmission), 'ddMMYYYY' , 'UTC'));
    // this.timeOfAdmission = Object.assign([],this.removeQuotes(this.preAuthData.timeOfAdmission));

    //this.firstConsultationDate = Object.assign([], datePipe.transform(this.removeQuotes(this.preAuthData.firstConsultationDate), 'ddMMYYYY' , 'UTC'));
  }

  age: any = '';
  months: any = '';
  calculateAge(dob: string) {
    const today = new Date();
    console.log(today);
    const dobDate = new Date(this.removeQuotes(dob));
    console.log(dobDate)
    let age = today.getFullYear() - dobDate.getFullYear();
    let months = today.getMonth() - dobDate.getMonth();
    if (months < 0) {
      age--;
      months += 12;
    }
    this.age = age + '';
    this.months = months + '';
    //console.log(`${this.age} years ${this.months} months`);
    // return `${this.age} years ${this.months} months`;
  }

  setInsuranceMediaId(data: any) {
    //var x = JSON.parse(data);
    for (var key in data) {
      // // console.log(key);
      //var k = key.replace(/^"|"$/g, "");
      if (key == "select-tpa-modal" && data["select-tpa-modal"] != "" && data.inhouseTPA == "inHouseTPANo") {
        this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
        this.getTpa();
        // console.log("tpaMediaId: " + JSON.stringify(this.insuranceMediaId));
      }
      else if (key == "select-primary-insurance-company-name-modal") {
        this.insuranceMediaId = data[key].replace(/^"|"$/g, "");
        this.getInsuranceCompany();
        // console.log("insuranceMediaId: " + JSON.stringify(this.insuranceMediaId));
      }
    }
  }

  getInsuranceCompany() {
    this.cashlessService
      .getSocForInsurance(this.insuranceMediaId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.insurance = data;
          console.log("prod", data);
        },
        error: (err: any) => {
          // console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Insurance Companies available!";
          }
        },
      });
  }

  async downloadPDF() {
    const data = document.getElementById("preauthContent");
    if (data) {
      const imgWidth = 208; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const contentHeight = data.scrollHeight; // Total content height in px
 
      html2canvas(data).then((canvas) => {
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
 
        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
 
        // Add first page
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
       
        // Calculate the number of pages needed
        const totalPages = Math.ceil(imgHeight / pageHeight);
 
        for (let i = 1; i < totalPages; i++) {
          pdf.addPage();
          position = -(i * pageHeight);
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        }
 
        pdf.save("preAuthForm.pdf");
      });
    
      /*const data = document.getElementById('preauthContent');
      const imgWidth = 208; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      if(data){

      
      const contentHeight = data.scrollHeight; // Total content height in px
 
      html2canvas(data).then((canvas) => {
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
 
        const pdf = new jsPDF("p", "mm", "a4");
        let position = 0;
 
        // Add first page
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
       
        // Calculate the number of pages needed
        const totalPages = Math.ceil(imgHeight / pageHeight);
 
        for (let i = 1; i < totalPages; i++) {
          pdf.addPage();
          position = -(i * pageHeight);
          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        }
 
        pdf.save("preAuthForm.pdf");
      });*/
    }
      /*const doc = new jsPDF('p', 'mm', 'a4'); // Portrait, millimeters, A4 size

      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;

      const marginTop = 20; // Top margin (for header)
      const marginBottom = 20; // Bottom margin (for footer)

      const header = 'Custom Header'; // Header content
      const footer = ''; // Footer content

      // Add content from the HTML div
      const contentElement = this.contentToConvert.nativeElement;

      // Use html2canvas to capture the content of the div
      html2canvas(contentElement).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - 10; // Adjust based on margins
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = marginTop;

        // Add the image to the first page
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight - marginTop - marginBottom;

        // Add headers and footers
        doc.setFontSize(10);
        //doc.text(header, pageWidth / 2, 5, { align: 'center' }); // Header at the top
        doc.text(`${footer} 1`, pageWidth / 2, pageHeight - 5, { align: 'center' }); // Footer at the bottom

        // Add new pages if the content is too large
        let page = 2;
        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 5, marginTop, imgWidth, imgHeight);
          heightLeft -= pageHeight - marginTop - marginBottom;

          // Add header and footer for each additional page
          doc.text(header, pageWidth / 2, 5, { align: 'center' }); // Header at the top
          doc.text(`${footer} ${page}`, pageWidth / 2, pageHeight - 5, { align: 'center' }); // Footer at the bottom
          page++;
        }

        // Save the PDF
        doc.save('document.pdf');
      });*/
    
  }

  getTpa() {
    this.cashlessService
      .getTpa(this.insuranceMediaId)
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.insurance = data;
          console.log("prod", data);
        },
        error: (err: any) => {
          // console.log(err);

          if (err.error && err.error.error) {
            this.error = err.error.error;
          } else {
            this.error = "No Tpa available!";
          }
        },
      });

  }

  getRoutes(routes: any) {
    console.log(typeof routes);

    return routes
      .split(', ')
      .map((route: any) => route.split('_').pop())
      .join(', ')
      .replace(/\]$/, '');
  }
}
