import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-privileges',
  templateUrl: './privileges.component.html',
  styleUrls: ['./privileges.component.scss']
})
export class PrivilegesComponent {
  pageSize = 10;
  pageIndex = 0;
  roles: any;
  message: any;
  priveleges: any;
  filteredArr: any;
  constructor(private adminService: AdminService,
    private _dialogRef: MatDialogRef<PrivilegesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      console.log("data",data);
  }

  ngOnInit(): void {
    this.cellClicked();
  }
//   cellClicked() {
//       // this.adminService.getUserRoleslById(this.user.id).pipe(first())
//       this.adminService.getUserRoleslById(this.data).pipe(first())
  
//         .subscribe({
//           next: (data: any) => {
//             //this.loading = false;
// //console.log("getUserRoleslById", data);
//             this.roles = data;
//           this.adminService.getPrivileges()
//             .pipe(first())
//             .subscribe({
//               next: (data: any) => {
//   //console.log("manageuserpriveleges", data)
//                 this.priveleges = data;
//                 this.filteredArr = this.priveleges?.filter((data: any) => {
//                   // return this.roles?.some((data1: any) => data1.rolePrivileges[0].id.privilegeId === data.id);
//                   return this.roles?.some((data1: any) => data1.rolePrivileges[0].id.privilegeId === data.id);

//                   // let result = employeeData.map(sub => sub.reduce((obj, pair) => (obj[pair[0]] = pair[1], obj), {}));

//                 });
//               },
//               error: error => {
//                 console.log(error);
//               }
//             });
//         },
//         error: error => {
//           console.log("error " + JSON.stringify(error))
//           this.message = error;
//         }
//       });
    
//     // this.adminService.getRoles(this.pageIndex * this.pageSize, this.pageSize).pipe(first())
//     //   .subscribe({
//     //     next: (data: any) => {
//     //       //this.loading = false;
//     //       console.log("rolesonmangeuser", data);
//     //       this.roles = data;
//     //       this.adminService.getPrivileges()
//     //         .pipe(first())
//     //         .subscribe({
//     //           next: (data: any) => {
//     //             console.log("manageuserpriveleges", data)
//     //             this.priveleges = data;
//     //             this.filteredArr = this.priveleges?.filter((data: any) => {
//     //               return this.roles?.some((data1: any) => data1.rolePrivileges[0].id.privilegeId === data.id);
//     //             });
//     //             console.log("filteredArr", this.filteredArr);
//     //           },
//     //           error: error => {
//     //             console.log(error);
//     //           }
//     //         });
//     //     },
//     //     error: error => {
//     //       console.log("error " + JSON.stringify(error))
//     //       this.message = error;
//     //     }
//     //   });
//     // this.filteredArr = this.priveleges?.filter((data:any) => {
//     //   return this.roles?.some((data1:any) =>  data1.rolePrivileges[0].id.privilegeId === data.id);
//     // });

//     // console.log("gyu",this.filteredArr);
//     // var combinedArray = this.roles.map((el: {rolePrivileges: any.id: any.privilegeId: any}) => ({
//     //   ...el,
//     //   ...arr1.find((arr1El: { id: any; }) => arr1El.id === el.rolePrivileges.id.privilegeId),
//     // }));
//   }
cellClicked() {
  this.adminService.getUserRoleslById(this.data)
    .pipe(first())
    .subscribe({
      next: (userRoles: any) => {
        console.log("User roles:", userRoles);
        this.roles = userRoles;
 
        this.adminService.getPrivileges()
          .pipe(first())
          .subscribe({
            next: (privileges: any) => {
              console.log("Manage user privileges:", privileges);
              this.priveleges = privileges;
 
              // Initialize an empty array to hold filtered privileges
              this.filteredArr = [];
 
              // Iterate through roles and privileges to find matches
              this.roles?.forEach((role: any) => {
                const matchingPrivileges = this.priveleges?.filter((privilege: any) =>
                  role.privileges.some((rolePrivilege: any) =>
                    // rolePrivilege.id.privilegeId === privilege.id
                      rolePrivilege.privilegeId === privilege.id

                  )
                );
                this.filteredArr = this.filteredArr.concat(matchingPrivileges);
              });
 
             // console.log("Filtered privileges:", this.filteredArr);
            },
            error: (error) => {
              console.log(error);
            },
          });
      },
      error: (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.message = error;
      },
    });
}

// cellClicked() {
//   this.adminService.getUserRoleslById(this.data)
//     .pipe(first())
//     .subscribe({
//       next: (userRoles: any) => {
//         console.log("User roles:", userRoles);
//         this.roles = userRoles;
 
        
//               // Iterate through roles and privileges to find matches
//               this.roles.priveleges?.forEach((role: any) => {
//                 console.log(role)
//                return this.roles = role;
//               });
 
//               console.log("Filtered privileges:", this.roles);
//             },
//             error: (error) => {
//               console.log(error);
        
        
//       },
//     });
// }
// cellClicked() {
//   this.adminService.getUserRoleslById(this.data)
//     .pipe(first())
//     .subscribe({
//       next: (userRoles: any) => {
//         console.log("User roles:", userRoles);
//         this.roles = userRoles;
//         // userRoles.privileges?.forEach((role: any) => {
//         //   this.roles = role;
//         //   console.log("User roles:", this.roles);

//         // });
//            },
//       error: (error) => {
//         console.log("Error: " + JSON.stringify(error));
//         this.message = error;
//       },
//     });
// }
 }
